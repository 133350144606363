import { Component, OnInit } from '@angular/core';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  userCancellationRequests: any[] = [];
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  totalPages = 0;
  Math = Math;
  sortBy: string = 'requested_at';
  sortOrder: 'asc' | 'desc' = 'desc';

  constructor(private cancellationService: CancellationService) {}

  ngOnInit() {
    this.loadUserCancellationRequests();
  }

  loadUserCancellationRequests() {
    this.cancellationService.getUserCancellations(
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortOrder
    ).subscribe(
      (response) => {
        if(response.success) {
          this.userCancellationRequests = response.data.cancellations;
          this.totalItems = response.data.totalCancellations;
          this.totalPages = response.data.totalPages;
        }
      },
      (error) => {
        console.error('Error loading user cancellation requests', error);
      }
    );
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadUserCancellationRequests();
    }
  }

  changeSort(column: string) {
    if (this.sortBy === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = column;
      this.sortOrder = 'asc';
    }
    this.currentPage = 1;
    this.loadUserCancellationRequests();
  }

  pageRange(): number[] {
    const rangeSize = 5;
    const range: number[] = [];
    const startPage = Math.max(1, this.currentPage - Math.floor(rangeSize / 2));
    const endPage = Math.min(this.totalPages, startPage + rangeSize - 1);

    for (let i = startPage; i <= endPage; i++) {
      range.push(i);
    }

    return range;
  }
}