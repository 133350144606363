<div>
    
<main class="flex flex-col w-full min-h-[100vh] bg-white">
        <div id="topSection" class="grid grid-cols-2 w-full h-[55vh]">
            <div class="flex flex-col gap-4 justify-center items-center">
                <img src="../../../../assets/images/dagPacket-logo.png" alt="Logo" class="mb-12 w-1/2">
                <h1 class="text-4xl font-semibold mx-8">Enfócate en lo que importa, déjanos los <span class="text-orange-500">envíos a nosotros</span></h1>
                <p class="text-2xl font-normal mx-8">Con nuestros servicios de envío internacional y nacional, puedes tener la tranquilidad de que tus paquetes llegarán a tiempo y en perfectas condiciones</p>
            </div>
            <div class="flex justify-center items-center">
                <ng-lottie [options]="band_lottie" (animationCreated)="onAnimate($event)" style="width: 50%; align-self: center;" ></ng-lottie>
            </div>
        </div>
        <div class="grid grid-cols-3 bg-red-600 w-full h-[45vh]">
            <div class="service1 service flex flex-col justify-around items-center bg-gray-100 w-full h-full relative overflow-hidden">
                <div class="circle absolute w-52 h-52 bg-[#FFFFFF35] rounded-full"></div>
                <div class="flex flex-col justify-center items-center px-12 gap-1">
                    <h3 class="text-3xl font-medium text-center z-10">Envíos</h3>
                    <p class="serviceInfo text-center">Pesa tu paquete, identifica tu locker, y realiza tu envío Nacional o Internacional</p>
                </div>
                <img src="./package.png" alt="Envio" class="w-1/3 z-10 transition-all">
                <a class="bg-orange-600 text-xl font-semibold text-black z-10 py-2 px-20 rounded-sm hover:bg-orange-700 hover:scale-110 transition-all" href="./envios.php">Ir</a>
            </div>
            <div class="slide1 absolute grid grid-cols-2 h-[55vh] bg-white z-20">
                <div class="flex flex-col gap-4 justify-center items-center">
                    <img src="../../../../assets/images/dagPacket-logo.png" alt="DagPacket Logo" class="w-1/2 mb-12">
                    <h1 class="text-4xl font-semibold mx-8">Realiza envíos <span class="text-orange-500">nacionales e internacionales</span></h1>
                    <p class="text-2xl font-normal mx-8">Pesa y dimensiona de forma fácil tu paquete, ingrésalo en el locker indicado y nosotros nos encargaremos de lo demás</p>
                </div>
                <div class="flex justify-center items-center">
                    <ng-lottie [options]="box_lottie" (animationCreated)="onAnimate($event)"></ng-lottie>
                </div>
            </div>
            <div class="service2 service flex flex-col justify-around items-center bg-gray-100 w-full h-full relative overflow-hidden">
                <div class="circle absolute w-52 h-52 bg-[#FFFFFF35] rounded-full"></div>
                <div class="flex flex-col justify-center items-center px-12 gap-1">
                    <h3 class="text-3xl font-medium text-center z-10">Recibir Paquetes</h3>
                    <p class="serviceInfo text-center">Escanea tu código QR y recolecta tu paquete en nuestros lockers</p>
                </div>
                <img src="./package.png" alt="Envio" class="w-1/3 z-10 transition-all">
                <a class="bg-orange-600 text-xl font-semibold text-black z-10 py-2 px-20 rounded-sm hover:bg-orange-700 hover:scale-110 transition-all" href="./recibir.php">Ir</a>
            </div>
            <div class="slide2 absolute grid grid-cols-2 h-[55vh] bg-white z-20">
                <div class="flex flex-col gap-4 justify-center items-center">
                    <img src="../../../../assets/images/dagPacket-logo.png" alt="DagPacket Logo" class="w-1/2 mb-12">
                    <h1 class="text-4xl font-semibold mx-8">Recoge tu paquete en<span class="text-orange-500">nuestros lockers</span></h1>
                    <p class="text-2xl font-normal mx-8">Escanea tu código QR o ingresa tu código y obtén de forma rápida y sencilla tu envío</p>
                </div>
                <div class="flex justify-center items-center">
                    
                </div>
            </div>
            <div class="service3 service flex flex-col justify-around items-center bg-gray-100 w-full h-full relative overflow-hidden">
                <div class="circle absolute w-52 h-52 bg-[#FFFFFF35] rounded-full"></div>
                <div class="flex flex-col justify-center items-center px-12 gap-1">
                    <h3 class="text-3xl font-medium text-center z-10">Recargas y Servicios</h3>
                    <p class="serviceInfo text-center">Recarga saldo a tu número o paga tus servicios favoritos de manera rápida</p>
                </div>
                <img src="./package.png" alt="Envio" class="w-1/3 z-10 transition-all">
                <a class="bg-orange-600 text-xl font-semibold text-black z-10 py-2 px-20 rounded-sm hover:bg-orange-700 hover:scale-110 transition-all" href="./recargas_servicios.php">Ir</a>
            </div>
            <div class="slide3 absolute grid grid-cols-2 h-[55vh] bg-white z-20">
                <div class="flex flex-col gap-4 justify-center items-center">
                    <img src="../../../../assets/images/dagPacket-logo.png" alt="DagPacket Logo" class="w-1/2 mb-12">
                    <h1 class="text-4xl font-semibold mx-8">Recarga saldo o <span class="text-orange-500">paga tus servicios</span></h1>
                    <p class="text-2xl font-normal mx-8">Paga en nuestras pantallas los servicios más comunes como luz, cable, entretenimiento o recarga saldo a tu número telefónico</p>
                </div>
                <div class="flex justify-center items-center">
                    <ng-lottie [options]="box_lottie" (animationCreated)="onAnimate($event)"></ng-lottie>
                </div>
            </div>
        </div>
    </main>
</div>