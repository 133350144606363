import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  userId: string | null = null; // ID capturado desde la URL
  userInfo: any = null; // Información del usuario
  errorMessage: string | null = null; // Para manejar errores

  constructor(
    private route: ActivatedRoute,
    private authLoginService: AuthLoginService
  ) {}

  ngOnInit(): void {
    // Captura el ID de la URL
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('id');
      if (this.userId) {
        this.loadUserDetails(this.userId);
      } else {
        this.errorMessage = 'ID no válido o no proporcionado.';
      }
    });
  }

  loadUserDetails(id: string): void {
    this.authLoginService.getUserProfile(id).subscribe(
      (response) => {
        console.log('Datos recibidos:', response); 
        this.userInfo = response.data; 
      },
      (error) => {
        console.error('Error al obtener los detalles del usuario:', error);
        this.errorMessage = 'No se pudo cargar la información del usuario.';
      }
    );
  }
  
  
}
