<div class="px-6 ml-36">
    <div class="bg-gray-100 rounded-lg w-[60vw] shadow-lg">
        <form #updateFormA="ngForm" (submit)="updateAddressA(updateFormA)" novalidate>
            <!-- Dirección y Guardar Cambios -->
            <div class="p-4 flex justify-between">
                <div class="flex-col p-2">                
                    <span class="font-bold text-lg"> Dirección </span>          
                    <p>{{ user.address.street }}</p>
                    <p>{{ user.address.settlement }}</p>
                </div>
                <div class="p-2">
                    <button [disabled]="updateFormA.invalid" class="bg-dagblue p-3 rounded-lg text-white font-bold duration-200 hover:bg-blue-600 disabled:opacity-50">
                        Guardar Cambios
                    </button>
                </div>
            </div>

            <!-- Formulario de Dirección -->
            <div class="px-4 py-4 flex justify-start w-full gap-6">
                <!-- Calle -->
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="street" id="street" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.street" #street="ngModel"/>
                    <label for="street" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 peer-focus:text-dagblue">Calle</label>
                    <div *ngIf="street.invalid && (street.dirty || street.touched)" class="text-red-500 text-sm">Calle es obligatoria.</div>
                </div>

                <!-- Ciudad -->
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="city" id="city" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.city" #city="ngModel"/>
                    <label for="city" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 peer-focus:text-dagblue">Ciudad</label>
                    <div *ngIf="city.invalid && (city.dirty || city.touched)" class="text-red-500 text-sm">Ciudad es obligatoria.</div>
                </div>
            </div>

            <!-- Estado y Correo Electrónico -->
            <div class="px-4 flex justify-start w-full gap-6">
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="text" name="state" id="state" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.state" #state="ngModel"/>
                    <label for="state" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 peer-focus:text-dagblue">Estado</label>
                    <div *ngIf="state.invalid && (state.dirty || state.touched)" class="text-red-500 text-sm">Estado es obligatorio.</div>
                </div>

                <!-- Correo Electrónico -->
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="email" name="email" id="email" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.email" #email="ngModel"/>
                    <label for="email" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 peer-focus:text-dagblue">Correo electrónico</label>
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-red-500 text-sm">Correo electrónico es obligatorio.</div>
                </div>
            </div>

            <!-- Código Postal (bloqueado) y Teléfono (solo 10 dígitos) -->
            <div class="px-4 flex justify-start w-full gap-6">
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="number" name="zip_code" id="zip_code" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " required [(ngModel)]="user.address.zip_code" #zip_code="ngModel" [readonly]="true"/>
                    <label for="zip_code" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 peer-focus:text-dagblue">C.P</label>
                    <div *ngIf="zip_code.invalid && (zip_code.dirty || zip_code.touched)" class="text-red-500 text-sm">C.P es obligatorio.</div>
                </div>

                <!-- Teléfono -->
                <div class="relative z-0 mb-5 group w-1/2">
                    <input type="tel" name="phone" id="phone" class="block rounded-lg p-3 w-full text-sm text-gray-900 border-2 appearance-none dark:focus:border-dagblue focus:outline-none focus:ring-0 focus:border-dagblue peer" placeholder=" " pattern="[0-9]{10}" required [(ngModel)]="user.phone" #phone="ngModel"/>
                    <label for="phone" class="pl-4 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-2 peer-focus:text-dagblue">Teléfono</label>
                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="text-red-500 text-sm">Teléfono es obligatorio y debe tener 10 dígitos.</div>
                </div>
            </div>

            <!-- Botón "Buscar en guardados" -->
            <div class="px-4 flex justify-start w-full gap-6">
                <button class="bg-dagblue text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200" type="button">
                    Buscar en guardados
                </button>
            </div>

        </form>             
    </div>

    <app-success-alert *ngIf="success" [message]="message"></app-success-alert>
    <app-success-alert *ngIf="error"></app-success-alert>
</div>
