import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.scss']
})
export class CajaComponent implements OnInit {
  cashiers: any[] = [];
  paginatedCashiers: any[] = [];
  filteredCashiers: any[] = [];
  searchTerm: string = '';
  startDate: string = '';
  endDate: string = '';
  currentPage: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  Math: any = Math;

  ngOnInit() {
    this.loadCashiers();
  }

  loadCashiers() {
    // Aquí deberías cargar los datos reales de los cajeros
    this.cashiers = [
      { name: 'Juan Pérez', email: 'juan@example.com', phone: '1234567890', status: 'Activo', dailyReport: 'Sí', customReport: 'No' },
      // ... más cajeros
    ];
    this.filteredCashiers = [...this.cashiers];
    this.updatePaginatedCashiers();
  }

  applyFilters() {
    this.filteredCashiers = this.cashiers.filter(cashier => {
      const matchesSearch = cashier.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                            cashier.email.toLowerCase().includes(this.searchTerm.toLowerCase());
      const cashierDate = new Date(cashier.createdAt); // Asume que hay un campo createdAt
      const afterStartDate = !this.startDate || cashierDate >= new Date(this.startDate);
      const beforeEndDate = !this.endDate || cashierDate <= new Date(this.endDate);
      return matchesSearch && afterStartDate && beforeEndDate;
    });
    this.currentPage = 1;
    this.updatePaginatedCashiers();
  }

  clearFilters() {
    this.searchTerm = '';
    this.startDate = '';
    this.endDate = '';
    this.filteredCashiers = [...this.cashiers];
    this.currentPage = 1;
    this.updatePaginatedCashiers();
  }

  updatePaginatedCashiers() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedCashiers = this.filteredCashiers.slice(startIndex, endIndex);
    this.totalItems = this.filteredCashiers.length;
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updatePaginatedCashiers();
    }
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  pageRange(): number[] {
    const range = 2;
    const start = Math.max(1, this.currentPage - range);
    const end = Math.min(this.totalPages, this.currentPage + range);
    return Array.from({length: (end - start + 1)}, (_, i) => start + i);
  }
}