import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-packing',
  templateUrl: './admin-packing.component.html',
  styleUrls: ['./admin-packing.component.scss']
})
export class AdminPackingComponent {

}
