import { Component, OnInit } from '@angular/core';
import { CashRegisterService } from 'src/app/_services/Cashiers/cash-register.service';

@Component({
  selector: 'app-cash-register-history',
  templateUrl: './cash-register-history.component.html',
  styleUrls: ['./cash-register-history.component.scss']
})
export class CashRegisterHistoryComponent implements OnInit {
  cashRegisters: any[] = [];
  currentPage = 1;
  totalPages = 1;
  itemsPerPage = 10;
  totalItems = 0;
  loading = false;
  error: string | null = null;
  startDate: string = '';
  endDate: string = '';
  Math = Math;

  constructor(private cashRegisterService: CashRegisterService) { }

  ngOnInit(): void {
    this.loadCashRegisters();
  }

  loadCashRegisters(): void {
    this.loading = true;
    this.cashRegisterService.getAllCashRegisters(
      this.currentPage,
      this.itemsPerPage,
      this.startDate,
      this.endDate
    ).subscribe(
      (response) => {
        this.cashRegisters = response.data.cashRegisters;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
        this.totalItems = response.data.totalRegisters;
        this.loading = false;
      },
      (error) => {
        this.error = 'Error al cargar los registros de caja';
        this.loading = false;
      }
    );
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadCashRegisters();
  }

  applyDateFilter(): void {
    this.currentPage = 1;
    this.loadCashRegisters();
  }

  clearDateFilter(): void {
    this.startDate = '';
    this.endDate = '';
    this.currentPage = 1;
    this.loadCashRegisters();
  }

  formatCurrency(amount: number): string {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
  }
}