<app-sidebar></app-sidebar>
<div class="header ml-16 mt-6 flex flex-col items-center justify-center h-screen">
    <div class="inner-header flex flex-col justify-center items-center gap-4">
        <img src="../../../assets/images/LogotipoA.png" alt="Logotipo" class="mx-auto mb-4 logo" style="max-width: 800px; height: auto;" />
        <h1>¡BIENVENIDO!</h1>
        <div class="mt-8">
            <button [routerLink]="['/settings']">
                Completar Perfil
            </button>
        </div>
    </div>
    <div class="bottom-[120px] right-[131px] fixed">
        <img src="../../../assets/images/Amibot.png" alt="Amibot" class="w-[262px] h-[262px]">
    </div>
</div>


