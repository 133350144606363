import { Component, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart, ChartData, ChartOptions } from 'chart.js/auto';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('doughnutCanvas') doughnutCanvas!: ElementRef;
  doughnutChart: any;

  @Input() doughnutChartData: ChartData<'doughnut'> | null = null;

  constructor() {}

  ngAfterViewInit(): void {
    this.createDoughnutChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['doughnutChartData'] && this.doughnutChart && this.doughnutChartData) {
      this.doughnutChart.data = this.doughnutChartData;
      this.doughnutChart.update();
    }
  }

  createDoughnutChart() {
    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: 'doughnut',
      data: this.doughnutChartData || {
        labels: ['SUPERENVIOS', 'UPS', '99minutos', 'DHL'],
        datasets: [{
          label: 'Errores compra guía',
          data: [0, 0, 0, 0],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF4500'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF4500']
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
          }
        }
      },
    });
  }
}
