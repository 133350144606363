import { Component, Inject } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cotizar',
  templateUrl: './cotizar.component.html',
  styleUrls: ['./cotizar.component.scss']
})
export class CotizarComponent {
  id: Observable<string>;
  url: Observable<string>;
  user: Observable<any>;

  constructor(@Inject(ActivatedRoute) private route: ActivatedRoute) {
    // Get the ID from the route parameters
    this.id = this.route.params.pipe(map(p => p['id']));

    // Get the URL as a string
    this.url = this.route.url.pipe(map((segments: UrlSegment[]) => segments.join('')));

    // Access 'data' and 'resolve' from route.data
    this.user = this.route.data.pipe(map(d => d['user']));
  }

}
