<app-sidebar></app-sidebar>
<div class="ml-36 pt-5 w-auto h-[100vh]">
  <div class="flex p-10 ml-36">
    <div class=" flex flex-col-reverse bg-[#F0F0F0] rounded-3xl w-[70vw] shadow-lg p-10">
      
    <div class="flex justify-center mt-5 mb-0">
      <button 
        type="button" 
        class="font-bold py-3 px-40 rounded-full transition duration-300 ease-in-out text-white w-auto"
        [ngClass]="{
          'bg-[#2C2851] hover:bg-opacity-90': fromForm.valid && toForm.valid, 
          'bg-gray-400 cursor-not-allowed': !fromForm.valid || !toForm.valid
        }"
        [disabled]="!fromForm.valid || !toForm.valid"
        (click)="onContinue()"
      >
        CONTINUAR
      </button>
    </div>

      <div class="grid grid-cols-2 gap-10">
        
        <!-- Formulario del Remitente -->
        <div>
          <h2 class="font-extrabold text-3xl text-[#332D69] text-center mb-6">REMITENTE</h2>
          <form #fromForm="ngForm">

            <!-- Dirección -->
            <div class="relative group mb-4">
              <input #fromAddressInput type="text" id="fromAddressInput" [(ngModel)]="from.address" name="fromAddress"
                class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
              <label for="fromAddressInput" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Dirección</label>
              <div *ngIf="fromForm.submitted && fromForm.controls['fromAddress'].invalid" class="text-red-500 text-sm">
                La dirección es obligatoria.
              </div>
            </div>

             <!-- Nombre completo -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromName" [(ngModel)]="from.name" id="fromName" #fromName="ngModel"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" 
                  placeholder=" " required minlength="5" />
                <label for="fromName" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Nombre Completo</label>
                <div *ngIf="fromName.invalid && (fromName.dirty || fromName.touched)" class="text-red-500 text-sm">
                  <div *ngIf="fromName.errors?.['required']">El nombre es obligatorio.</div>
                  <div *ngIf="fromName.errors?.['minlength']">El nombre debe tener al menos 5 caracteres.</div>
                </div>
              </div>          
              <div class="relative w-1/2 group mb-4">
                <input type="email" name="fromEmail" [(ngModel)]="from.email" id="fromEmail" #fromEmail="ngModel"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" 
                  placeholder=" " required email />
                <label for="fromEmail" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Correo electrónico</label>
                <div *ngIf="fromEmail.invalid && (fromEmail.dirty || fromEmail.touched)" class="text-red-500 text-sm">
                  <div *ngIf="fromEmail.errors?.['required']">El correo es obligatorio.</div>
                  <div *ngIf="fromEmail.errors?.['email']">Ingrese un correo electrónico válido.</div>
                </div>
              </div>
            </div>

            <!-- Teléfono -->
            <div class="relative group mb-4">
              <input type="tel" name="fromPhone" [(ngModel)]="from.phone" #fromPhone="ngModel" pattern="[0-9]{10}" maxlength="10"
                class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
              <label for="fromPhone" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Teléfono</label>
              <div *ngIf="fromPhone.invalid && (fromPhone.dirty || fromPhone.touched)" class="text-red-500 text-sm">
                El teléfono debe tener exactamente 10 dígitos.
              </div>
            </div>

            <!-- País y Código Postal -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromCountry" id="fromCountry" [(ngModel)]="from.country"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
                <label for="fromCountry" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">País</label>
                <div *ngIf="fromForm.submitted && fromForm.controls['fromCountry'].invalid" class="text-red-500 text-sm">
                  El país es obligatorio.
                </div>
              </div>          
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromZipCode" id="fromZipCode" [(ngModel)]="from.zip_code"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required readonly />
                <label for="fromZipCode" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">C.P.</label>
                <div *ngIf="fromForm.submitted && fromForm.controls['fromZipCode'].invalid" class="text-red-500 text-sm">
                  El código postal es obligatorio.
                </div>
              </div>
            </div>

            <!-- Estado y Ciudad -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromState" id="fromState" [(ngModel)]="from.state"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
                <label for="fromState" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Estado</label>
                <div *ngIf="fromForm.submitted && fromForm.controls['fromState'].invalid" class="text-red-500 text-sm">
                  El estado es obligatorio.
                </div>
              </div>
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromCity" id="fromCity" [(ngModel)]="from.city"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
                <label for="fromCity" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Ciudad</label>
                <div *ngIf="fromForm.submitted && fromForm.controls['fromCity'].invalid" class="text-red-500 text-sm">
                  La ciudad es obligatoria.
                </div>
              </div>
            </div>
            <div class="flex gap-4 mb-4">
              <div class="relative w-full group mb-4">
                    <input type="text" name="fromSettlement" id="fromSettlement" [(ngModel)]="from.settlement"
                    class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                    focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent"
                    placeholder="" required />
                    <label for="fromSettlement" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-5">Colonia</label>
              </div>
              <div *ngIf="fromForm.submitted && fromForm.controls['fromSettlement'].invalid" class="text-red-500 text-sm">
                  La colonia es obligatoria.
                </div>
            </div>

            <!-- Número exterior e interior -->
            <div class="flex gap-4 mb-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromExternalNumber" id="fromExternalNumber" [(ngModel)]="from.external_number"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
                <label for="fromExternalNumber" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Número Exterior</label>
                <div *ngIf="fromForm.submitted && fromForm.controls['fromExternalNumber'].invalid" class="text-red-500 text-sm">
                  El número exterior es obligatorio.
                </div>
              </div>
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="fromInternalNumber" id="fromInternalNumber" [(ngModel)]="from.internal_number"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " />
                <label for="fromInternalNumber" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Número Interior (opcional)</label>
              </div>
            </div>
            
            <!-- Referencia -->
            <div class="relative group mb-4">
              <input type="text" name="fromReference" id="fromReference" [(ngModel)]="from.reference"
                class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " />
              <label for="fromReference" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Referencia</label>
            </div>

          </form>

          <!-- Botones -->
          <div class="flex flex-col justify-center mt-6 gap-4">
            <button (click)="saveAddress('remitente', fromForm)" type="button" class="bg-[#D6542B] text-white font-bold py-3 rounded-full w-full">
              GUARDAR DIRECCIÓN
            </button>            
            <button (click)="openAddressDialog('remitente')" type="button" class="border-2 border-[#D6542B] text-[#D6542B] font-bold py-3 rounded-full w-full">
              BUSCAR EN GUARDADOS
            </button>
          </div>
        </div>

        <!-- Formulario del Destinatario -->
        <div>
          <h2 class="font-extrabold text-3xl text-[#332D69] text-center mb-6">DESTINATARIO</h2>
          <form #toForm="ngForm">

            <!-- Dirección -->
            <div class="relative group mb-4">
              <input #toAddressInput type="text" id="toAddressInput" [(ngModel)]="to.address" name="toAddress"
                class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
              <label for="toAddressInput" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Dirección</label>
              <div *ngIf="toForm.submitted && toForm.controls['toAddress'].invalid" class="text-red-500 text-sm">
                La dirección es obligatoria.
              </div>
            </div>

            <!-- Nombre completo -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toName" [(ngModel)]="to.name" id="toName" #toName="ngModel"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" 
                  placeholder=" " required minlength="5" />
                <label for="toName" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Nombre Completo</label>
                <div *ngIf="toName.invalid && (toName.dirty || toName.touched)" class="text-red-500 text-sm">
                  <div *ngIf="toName.errors?.['required']">El nombre es obligatorio.</div>
                  <div *ngIf="toName.errors?.['minlength']">El nombre debe tener al menos 5 caracteres.</div>
                </div>
              </div>          
              <div class="relative w-1/2 group mb-4">
                <input type="email" name="toEmail" [(ngModel)]="to.email" id="toEmail" #toEmail="ngModel"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" 
                  placeholder=" " required email />
                <label for="toEmail" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Correo electrónico</label>
                <div *ngIf="toEmail.invalid && (toEmail.dirty || toEmail.touched)" class="text-red-500 text-sm">
                  <div *ngIf="toEmail.errors?.['required']">El correo es obligatorio.</div>
                  <div *ngIf="toEmail.errors?.['email']">Ingrese un correo electrónico válido.</div>
                </div>
              </div>
            </div>

            <!-- Teléfono -->
            <div class="relative group mb-4">
              <input type="tel" name="toPhone" [(ngModel)]="to.phone" #toPhone="ngModel" pattern="[0-9]{10}" maxlength="10"
                class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
              <label for="toPhone" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Teléfono</label>
              <div *ngIf="toPhone.invalid && (toPhone.dirty || toPhone.touched)" class="text-red-500 text-sm">
                El teléfono debe tener exactamente 10 dígitos.
              </div>
            </div>

            <!-- País y Código Postal -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toCountry" id="toCountry" [(ngModel)]="to.country"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
                <label for="toCountry" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">País</label>
                <div *ngIf="toForm.submitted && toForm.controls['toCountry'].invalid" class="text-red-500 text-sm">
                  El país es obligatorio.
                </div>
              </div>          
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toZipCode" id="toZipCode" [(ngModel)]="to.zip_code"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required readonly />
                <label for="toZipCode" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">C.P.</label>
                <div *ngIf="toForm.submitted && toForm.controls['toZipCode'].invalid" class="text-red-500 text-sm">
                  El código postal es obligatorio.
                </div>
              </div>
            </div>

            <!-- Estado y Ciudad -->
            <div class="flex gap-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toState" id="toState" [(ngModel)]="to.state"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
                <label for="toState" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Estado</label>
                <div *ngIf="toForm.submitted && toForm.controls['toState'].invalid" class="text-red-500 text-sm">
                  El estado es obligatorio.
                </div>
              </div>
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toCity" id="toCity" [(ngModel)]="to.city"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
                <label for="toCity" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Ciudad</label>
                <div *ngIf="toForm.submitted && toForm.controls['toCity'].invalid" class="text-red-500 text-sm">
                  La ciudad es obligatoria.
                </div>
              </div>
            </div>
            <div class="flex gap-4 mb-4">
              <div class="relative w-full group mb-4">
                    <input type="text" name="toSettlement" id="toSettlement" [(ngModel)]="to.settlement"
                    class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                    focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent"
                    placeholder="" required />
                    <label for="toSettlement" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-5">Colonia</label>
              </div>
              <div *ngIf="fromForm.submitted && fromForm.controls['toSettlement'].invalid" class="text-red-500 text-sm">
                  La colonia es obligatoria.
                </div>
            </div>

            <!-- Número exterior e interior -->
            <div class="flex gap-4 mb-4">
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toExternalNumber" id="toExternalNumber" [(ngModel)]="to.external_number"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " required />
                <label for="toExternalNumber" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Número Exterior</label>
                <div *ngIf="toForm.submitted && toForm.controls['toExternalNumber'].invalid" class="text-red-500 text-sm">
                  El número exterior es obligatorio.
                </div>
              </div>
              <div class="relative w-1/2 group mb-4">
                <input type="text" name="toInternalNumber" id="toInternalNumber" [(ngModel)]="to.internal_number"
                  class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                  focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " />
                <label for="toInternalNumber" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                  -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                  peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Número Interior (opcional)</label>
              </div>
            </div>
            
            <!-- Referencia -->
            <div class="relative group mb-4">
              <input type="text" name="toReference" id="toReference" [(ngModel)]="to.reference"
                class="floating-input block rounded-full pt-8 pb-2 px-4 w-full text-sm text-gray-900 border border-gray-300
                focus:outline-none focus:ring-0 focus:border-[#2C2851] peer placeholder-transparent" placeholder=" " />
              <label for="toReference" class="floating-label absolute left-4 text-gray-500 duration-300 transform
                              -translate-y-3 scale-100 origin-[0] top-4 peer-placeholder-shown:top-4 peer-placeholder-shown:scale-100
                              peer-placeholder-shown:translate-y-0 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6">Referencia</label>
                          </div>
              
                          </form>
                            
                            <!-- Botones -->
                            <div class="flex flex-col justify-center mt-6 gap-4">
                              <button (click)="saveAddress('destinatario', toForm)" type="button" class="bg-[#D6542B] text-white font-bold py-3 rounded-full w-full">
                                GUARDAR DIRECCIÓN
                              </button>            
                              <button (click)="openAddressDialog('destinatario')" type="button" class="border-2 border-[#D6542B] text-[#D6542B] font-bold py-3 rounded-full w-full">
                                BUSCAR EN GUARDADOS
                              </button>
                            </div>          
                           
                        </div>
                        </div>

                      <!-- Modal para direcciones guardadas -->
                      <div *ngIf="isAddressDialogOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                        <div class="bg-[#F0F0F0] p-6 rounded-3xl shadow-xl w-full max-w-lg">
                          <div class="flex justify-between items-center mb-6">
                            <h2 class="text-2xl font-extrabold text-[#2C2851]">DIRECCIONES GUARDADAS</h2>
                            <button (click)="closeAddressDialog()" class="text-[#2C2851] hover:text-gray-700"></button>
          
    <!-- Modal para direcciones guardadas -->
    <div *ngIf="isAddressDialogOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div class="bg-[#F0F0F0] p-6 rounded-3xl shadow-xl w-full max-w-lg">
      <div class="flex justify-between items-center mb-6">
        <h2 class="text-2xl font-extrabold text-[#2C2851]">DIRECCIONES GUARDADAS</h2>
        <button (click)="closeAddressDialog()" class="text-[#2C2851] hover:text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </button>
      </div>

      <div class="mb-4">
        <input 
        type="text" 
        [(ngModel)]="searchTerm" 
        (ngModelChange)="filterAddresses()"
        placeholder="Nombre" 
        class="block rounded-full p-4 w-full text-sm text-gray-900 border border-gray-300 focus:outline-none focus:ring-0 focus:border-[#2C2851]"
        >
      </div>

      <div class="max-h-96 overflow-y-auto">
        <ul *ngIf="filteredAddresses.length > 0; else noAddresses">
        <li *ngFor="let address of filteredAddresses" class="mb-4">
          <button (click)="selectAddress(address)" class="w-full text-left p-2">
          <div class="font-bold text-[#2C2851]">{{ address.name }}</div>
          <div class="text-sm text-gray-600">
            {{ address.street }}, {{ address.city }}, {{ address.state }}, CP {{ address.zip_code }}
          </div>
          </button>
        </li>
        </ul>
        <ng-template #noAddresses>
        <p class="text-center text-gray-500">No se encontraron direcciones.</p>
        </ng-template>
      </div>
      </div>
    </div>