import { Component } from '@angular/core';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {

  currentPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  message: string = '';
  success: boolean = false;

  changePassword() {
    // Implementa la lógica para cambiar la contraseña
  }

  togglePasswordVisibility(field: 'current' | 'new' | 'confirm') {
    // Implementa la lógica para mostrar/ocultar la contraseña
  }

}
