import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

interface Panel {
  id: any;
  name: string;
  hasAd: boolean;
  images: FileWithPreview[];
  currentIndex: number;
  isEditing: boolean;
  selectedImage1?: string;
  selectedImage2?: string;
}

interface FileWithPreview {
  file: File | null;
  preview: string;
}

@Component({
  selector: 'app-publicidad',
  templateUrl: './publicidad.component.html',
  styleUrls: ['./publicidad.component.scss']
})
export class PublicidadComponent implements OnInit {
  isModalOpen = false;
  currentPanel: Panel | null = null;
  intervaloTiempo: number = 20;
  ingresosPorAnuncio: number = 0;
  contratoPDF: File | null = null;
  tempImages: FileWithPreview[] = [];
  enterprise: string = '';
  panels: Panel[] = [
    { id: '66e1de7939795e082fa4d34e', name: 'Minerva', hasAd: true, images: [], currentIndex: 0, isEditing: false },
    { id: 2, name: 'Centro', hasAd: true, images: [], currentIndex: 0, isEditing: false },
    { id: 3, name: 'Plaza del Sol', hasAd: false, images: [], currentIndex: 0, isEditing: false },
    { id: 4, name: 'Andares', hasAd: false, images: [], currentIndex: 0, isEditing: false },
    { id: 5, name: 'Valle Real', hasAd: false, images: [], currentIndex: 0, isEditing: false },
    { id: 6, name: 'Minerva', hasAd: true, images: [], currentIndex: 0, isEditing: false },
  ];

  constructor(private http: HttpClient ) {}

  ngOnInit(): void {
    this.loadPanels();
  }

  loadPanels(): void {
  }

  openModal(panel: Panel): void {
    this.currentPanel = panel;
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.currentPanel = null;
    this.tempImages = [];
    this.contratoPDF = null;
    this.enterprise = '';
  }

  onImageSelected(event: Event, panelId: number, imageNumber: number): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const panel = this.panels.find(p => p.id === panelId);
        if (panel) {
          if (imageNumber === 1) {
            panel.selectedImage1 = e.target.result;
          } else if (imageNumber === 2) {
            panel.selectedImage2 = e.target.result;
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }
  

  onPDFSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.contratoPDF = input.files[0];
      console.log('Contrato PDF:', this.contratoPDF);
    }

  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      Array.from(input.files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.tempImages.push({ file, preview: e.target.result });
        };
        reader.readAsDataURL(file);
      });
    }
  }

  guardarPublicidad(): void {
    console.log('Current Panel:', this.currentPanel);
    console.log('TempImages Length:', this.tempImages.length);
    console.log('Intervalo Tiempo:', this.intervaloTiempo);
    console.log('Contrato PDF:', this.contratoPDF);
    console.log('Enterprise Name:', this.enterprise);
  
    if (this.currentPanel && this.tempImages.length > 0 && this.intervaloTiempo >= 20 && this.intervaloTiempo <= 30 && this.contratoPDF && this.enterprise) {
      const formData = new FormData();
      formData.append('locker_id', this.currentPanel.id.toString());
      formData.append('enterprise', this.enterprise);
      formData.append('type', 'digital');
      formData.append('duration', this.intervaloTiempo.toString());
      formData.append('income', this.ingresosPorAnuncio.toString());
      formData.append('description', 'Descripción del anuncio');
      formData.append('contract', this.contratoPDF); // Asegúrate de que el archivo PDF se esté agregando aquí
      this.tempImages.forEach((fileWithPreview, index) => {
        formData.append(`images[${index}][url]`, fileWithPreview.preview);
        formData.append(`images[${index}][alt]`, `Publicidad ${index + 1}`);
        formData.append(`images[${index}][image64]`, 'base64string');
      });

      console.log('Data being sent to backend:', formData);

      const data = {
        locker_id: this.currentPanel.id,
        enterprise: this.enterprise,
        type: 'digital',
        duration: this.intervaloTiempo,
        income: this.ingresosPorAnuncio,
        description: 'Descripción del anuncio',
        contract: this.contratoPDF,
        images: this.tempImages.map(fileWithPreview => ({
          url: fileWithPreview.preview,
          alt: 'Publicidad',
          image64: fileWithPreview.file
        }))
      }

      console.log('Data:', data);

 this.http.post(`${environment.apiUrl}/publicity/create`, formData).subscribe(response => {
      console.log('Publicidad guardada:', response);
      this.loadPanels();
      this.closeModal();
    }, error => {
      console.log('Datos enviados a la API:', formData);
      console.error('Error al guardar la publicidad:', error);
    });
  }
}
  removeTempImage(index: number): void {
    URL.revokeObjectURL(this.tempImages[index].preview);
    this.tempImages.splice(index, 1);
  }

  removeSelectedImage(panelId: number, imageNumber: number): void {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel) {
      if (imageNumber === 1) {
        panel.selectedImage1 = undefined;
      } else if (imageNumber === 2) {
        panel.selectedImage2 = undefined;
      }
    }
  }

  removeImage(panelId: number, index: number): void {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel) {
      URL.revokeObjectURL(panel.images[index].preview);
      panel.images.splice(index, 1);
      if (panel.currentIndex >= panel.images.length) {
        panel.currentIndex = panel.images.length - 1;
      }
    }
  }

  getTransform(panelId: number): string {
    const panel = this.panels.find(p => p.id === panelId);
    return panel ? `translateX(-${panel.currentIndex * 100}%)` : 'translateX(0)';
  }

  prevImage(panelId: number): void {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel && panel.images.length > 1) {
      panel.currentIndex = (panel.currentIndex - 1 + panel.images.length) % panel.images.length;
    }
  }

  nextImage(panelId: number): void {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel && panel.images.length > 1) {
      panel.currentIndex = (panel.currentIndex + 1) % panel.images.length;
    }
  }

  toggleEditMode(panelId: number): void {
    const panel = this.panels.find(p => p.id === panelId);
    if (panel) {
      panel.isEditing = !panel.isEditing;
    }
  }

  onEnterpriseChange(event: any): void {
    this.enterprise = event.target.value;
  }
}