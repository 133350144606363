import { Component, OnInit } from '@angular/core';
import { GavetaService } from 'src/app/_services/gavetas/gavetas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-gabetas-log',
  templateUrl: './gabetas-log.component.html',
  styleUrls: ['./gabetas-log.component.scss']
})
export class GabetasLogComponent implements OnInit {

  gavetas: any[] = []; // Inicializa el arreglo para almacenar los logs de gavetas
  id_gabeta!: string; // Afirmación no nula
  currentPage: number = 1; // Página actual
  itemsPerPage: number = 10; // Elementos por página
  totalPages: number = 0; // Total de páginas

  constructor(
    private location: Location, // Inyecta el servicio Location
    private gavetaService: GavetaService, 
    private route: ActivatedRoute,
    private router: Router // Inyecta el servicio Router
  ) {
    console.log('GabetasLogComponent creado'); // Para confirmar la creación del componente
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id_gabeta = params['id'];
      this.getGavetaLogs(this.id_gabeta);
    });
  }

  goBack() {
    this.location.back();  // Volver a la página anterior en el historial
  }

  getGavetaLogs(id_gabeta: string): void {
    this.gavetaService.getLogsGaveta(id_gabeta).subscribe(
      (logs) => {
        this.gavetas = logs.message; // Almacena los logs en la propiedad gavetas
        this.totalPages = Math.ceil(this.gavetas.length / this.itemsPerPage); // Calcula el total de páginas
        console.log(this.gavetas); // Para verificar que los datos se recibieron correctamente
      },
      (error) => {
        console.error('Error al obtener los logs de la gaveta:', error);
      }
    );
  }

  // Método para ir a la página anterior
  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  // Método para ir a la siguiente página
  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }



  get paginatedGavetas() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.gavetas.slice(start, end);
  }
}
