<!-- shipping-kiosk.component.html -->
<div class="container mx-auto p-4">
    <div class="flex justify-center p-6">
        <img src="../../../assets/images/dagPacket-logo.png" alt="" class="max-w-[37vw]">    
    </div>
  
    <div [ngSwitch]="currentStep">
      <div *ngSwitchCase="1">
        <h2 class="text-2xl font-semibold mb-4">Paso 1: Pesar y Medir su Paquete</h2>
        <div class="bg-gray-100 p-6 rounded-lg shadow-md">
          <img src="assets/scale-icon.svg" alt="Báscula" class="mx-auto mb-4 w-32">
          <p class="mb-4 text-center text-3xl">Coloque su paquete en la báscula y use el escáner lateral para medir las dimensiones.</p>
          <div class="text-center mb-4 flex justify-between p-4">
            <p class="text-2xl font-bold">Peso: {{packageWeight}} kg</p>
            <p class="text-2xl font-bold"> Alto: {{packageDimensions.height}}cm</p>
            <p class="text-2xl font-bold"> Largo: {{packageDimensions.length}}cm</p>
            <p class="text-2xl font-bold"> Ancho: {{packageDimensions.width}}cm </p>
          </div>
          <button (click)="nextStep()" class="bg-dagblue text-white font-bold py-2 px-4 rounded w-full">
            Continuar
          </button>
        </div>
      </div>
  
      <div *ngSwitchCase="2">
        <h2 class="text-2xl font-semibold mb-4">Paso 2: Cotizar su Envío</h2>
        <div class="bg-gray-100 p-6 rounded-lg shadow-md">
          <div class="text-center mb-4 flex justify-between p-4">
            <p class="text-2xl font-bold">Peso: {{packageWeight}} kg</p>
            <p class="text-2xl font-bold"> Alto: {{packageDimensions.height}}cm</p>
            <p class="text-2xl font-bold"> Largo: {{packageDimensions.length}}cm</p>
            <p class="text-2xl font-bold"> Ancho: {{packageDimensions.width}}cm </p>
          </div>
          <div class="mb-4">
            <p>Origen: {{origin}}</p>
            <p>Destino: <input [(ngModel)]="destination" (ngModelChange)="updateDestination($event)" class="border p-2 rounded"></p>
          </div>          
          <div class="flex justify-between">
            <button (click)="previousStep()" class="bg-gray-300 text-black font-bold py-2 px-4 rounded">
              Volver
            </button>
            <button (click)="nextStep()" class="bg-dagblue text-white font-bold py-2 px-4 rounded">
              Continuar
            </button>
          </div>
        </div>
      </div>
  
      <div *ngSwitchCase="3">
        <h2 class="text-2xl font-semibold mb-4">Paso 3: Elegir Paquetería</h2>
        <div class="bg-gray-100 p-6 rounded-lg shadow-md">
          <div class="grid grid-cols-1 gap-4 mb-4">
            <button (click)="selectCourier('DHL')" class="bg-white p-4 rounded-lg shadow flex justify-between items-center" [class.border-dagblue]="selectedCourier === 'DHL'">
              <div class="flex items-center">
                <img src="https://imgs.search.brave.com/XotgOD_Xpv2yQm5oRsjcJThfTE-8lo2q7IplEkFs5PY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9sb2dv/ZG93bmxvYWQub3Jn/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDE1/LzEyL2RobC1sb2dv/LTAtMS5wbmc" alt="DHL" class="w-32 h-32 mr-2 rounded-lg">
                <span>DHL</span>
              </div>
              <span>Estándar</span>
              <span>2-3 días</span>
              <span>$100.00</span>
            </button>
            <button (click)="selectCourier('FedEx')" class="bg-white p-4 rounded-lg shadow flex justify-between items-center" [class.border-dagblue]="selectedCourier === 'FedEx'">
              <div class="flex items-center">
                <img src="https://imgs.search.brave.com/rzp89Cd4a8wjGoahhWsyyTTXUPBmFlp_OYquSqy_kAk/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbmti/b3RkZXNpZ24uY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDEy/LzA5L0ZlZEV4LUxv/Z28tRGVzaWduLnBu/Zw" alt="FedEx" class="w-32 h-32 mr-2 rounded-lg">
                <span>FedEx</span>
              </div>
              <span>Express</span>
              <span>1-2 días</span>
              <span>$120.00</span>
            </button>
            <button (click)="selectCourier('UPS')" class="bg-white p-4 rounded-lg shadow flex justify-between items-center" [class.border-dagblue]="selectedCourier === 'UPS'">
              <div class="flex items-center">
                <img src="https://imgs.search.brave.com/CuvaJ_soSwH0G2gAJ7TP7sDxbVk1y82vNOg3QYfqYh8/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9sb2dv/cy1kb3dubG9hZC5j/b20vd3AtY29udGVu/dC91cGxvYWRzLzIw/MTYvMDgvVVBTX2xv/Z28tNTk3eDcwMC5w/bmc" alt="UPS" class="w-32 h-32 mr-2 rounded-lg">
                <span>UPS</span>
              </div>
              <span>Estándar</span>
              <span>2-4 días</span>
              <span>$90.00</span>
            </button>
          </div>
          <div class="flex justify-between">
            <button (click)="previousStep()" class="bg-gray-300 text-black font-bold py-2 px-4 rounded">
              Volver
            </button>
            <button (click)="finalize()" class="bg-dagblue text-white font-bold py-2 px-4 rounded">
              Finalizar y Pagar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- weighing.component.html -->
<div class="bg-white rounded-lg shadow-md p-6">
    <h2 class="text-2xl font-semibold mb-4 text-center">Paso {{currentStep}}: Pesar y Medir su Paquete</h2>
    <img src="../../../assets/images/scale-icon.svg" alt="Báscula" class="mx-auto mb-4 w-32">
    <p class="mb-4 text-center">{{ stepInstructions[currentStep] }}</p>
    <div class="text-center mb-4">
      <p>Peso: {{packageWeight}} kg</p>
      <p>Dimensiones: {{packageDimensions.length}}cm x {{packageDimensions.width}}cm x {{packageDimensions.height}}cm</p>
    </div>
    <div class="flex justify-between mb-4">
      <button (click)="previousStep()" [disabled]="currentStep === 1" class="bg-gray-500 text-white font-bold py-2 px-4 rounded disabled:opacity-50">
        Anterior
      </button>
      <button (click)="speakInstructions()" class="bg-dagpk text-white font-bold py-2 px-4 rounded">
        Repetir Instrucciones
      </button>
      <button (click)="nextStep()" [disabled]="currentStep === 3" class="bg-dagblue text-white font-bold py-2 px-4 rounded disabled:opacity-50">
        {{ currentStep === 3 ? 'Finalizar' : 'Siguiente' }}
      </button>
    </div>
  </div>