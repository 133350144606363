import { Component, OnInit, OnDestroy,NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { ShipmentData, Address } from 'src/app/_models/shipments';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  
  shipmentData: any;
  fromData: Address = {} as Address;
  toData: Address = {} as Address;
  paqueteriaData: any;
  discountType: 'subtract' | 'add' = 'subtract';
  discountAmount: number = 0;

  from: any = {};
  to: any = {};
  isEditModalOpen = false;
  selectedFormType: 'remitente' | 'destinatario' = 'remitente';
  editForm: any = {};;

  extraPrice: number = 0;  
  folio = '';
  userId!: any;
  subtotal: number = 0;
  packageCost: number = 0;
  discount: number = 0;
  total: number = 0;
  dagpacketProfit: number = 0;
  
  isPackageDialogOpen = false;
  isDiscountDialogOpen = false;
  selectedPackage: any = null;
  description: string = '';

  dimensionesData: any = {
    alto: 0,
    ancho: 0,
    largo: 0,
    peso: 0
  };
  
  private subscriptions: Subscription[] = [];
  Math: any = Math;

  constructor(
    private sharedDataService: SharedDataService,
    private shipmentService: ShipmentsService,
    private authService: AuthLoginService,
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone,
  ) {}

  ngOnInit() {
    this.userId = this.authService.getId();
    this.loadShipmentData();
    console.log('Shipment data:', this.shipmentData);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private loadShipmentData() {
    this.route.queryParams.subscribe(params => {
      if (params['datosEnvio']) {
        this.shipmentData = JSON.parse(params['datosEnvio']);
        this.fromData = this.shipmentData.from;
        this.toData = this.shipmentData.to;
        this.paqueteriaData = this.shipmentData.paqueteria;
        this.dimensionesData = this.shipmentData.dimensiones || {
          alto: 0,
          ancho: 0,
          largo: 0,
          peso: 0
        };
        
        this.subtotal = parseFloat(this.paqueteriaData.precio);
        this.total = this.subtotal;
        this.dagpacketProfit = this.shipmentData.dagpacketProfit;
        
        if (this.shipmentData.packing) {
          this.selectedPackage = {
            _id: this.shipmentData.packing.packing_id,
            name: this.shipmentData.packing.packing_type,
            price: parseFloat(this.shipmentData.packing.packing_cost)
          };
          this.packageCost = this.selectedPackage.price;
        }
        
        this.updateTotal();
      }
    });
  }

  resetExtraCost() {
    this.extraPrice = 0;
    this.updateTotal();
  }

  openDiscountDialog(): void {
    this.isDiscountDialogOpen = true;
    this.discountAmount = this.discount; // Inicializar con el descuento actual
  }

  closeDiscountDialog(): void {
    this.isDiscountDialogOpen = false;
  }

  openPackageDialog() {
    this.isPackageDialogOpen = true;
  }

  closePackageDialog() {
    this.isPackageDialogOpen = false;
  }

  selectPackage(pkg: any) {
    this.selectedPackage = pkg;
    this.packageCost = parseFloat(pkg.price);
    this.shipmentData.packing = {
      answer: 'Si',
      packing_id: pkg._id,
      packing_type: pkg.name,
      packing_cost: this.packageCost.toString()
    };
    this.updateTotal();
    this.closePackageDialog();
  }

  removePackage() {
    this.selectedPackage = null;
    this.packageCost = 0;
    this.shipmentData.packing = {
      answer: 'No',
      packing_type: 'None',
      packing_cost: '0'
    };
    this.updateTotal();
  }

  updateTotal() {
    this.total = this.subtotal + this.packageCost + this.extraPrice - this.discount;
    this.shipmentData.cost = this.paqueteriaData.precioOriginal.toString();
    this.shipmentData.price = this.total.toString();
    this.shipmentData.extra_price = this.extraPrice.toString();
    this.shipmentData.discount = this.discount.toString();
    this.shipmentData.dagpacket_profit = this.dagpacketProfit.toString();
  }
  
  addExtraCost(cost: number) {
    this.extraPrice += cost;
    this.updateTotal();
  }
  
  applyDiscount() {
    this.discount = Math.min(this.discountAmount, this.subtotal + this.packageCost + this.extraPrice);
    this.updateTotal();
    this.closeDiscountDialog();
    console.log('Descuento aplicado:', this.discount);
  }
  
  
  createNewShipment() {
    const newShipment: ShipmentData = {
      user_id: this.userId,
      shipment_type: this.shipmentData.shippingType as 'Paquete' | 'Sobre',
      from: this.fromData,
      to: this.toData,
      payment: {
        method: 'saldo',
        status: 'Pendiente'
      },
      packing: this.shipmentData.packing || {
        answer: 'No',
        packing_type: 'None',
        packing_cost: '0'
      },
      shipment_data: {
        height: this.dimensionesData.alto,
        width: this.dimensionesData.ancho,
        length: this.dimensionesData.largo,
        package_weight: this.dimensionesData.peso
      },
      insurance: this.shipmentData.valor_declarado ? this.shipmentData.valor_declarado.toString() : '0',
      cost: this.paqueteriaData.precioOriginal.toString(),
      price: this.total.toString(),
      extra_price: this.extraPrice.toString(),
      discount: this.discount.toString(),
      status: 'En recolección',
      dagpacket_profit: this.dagpacketProfit.toString(),
      description: this.shipmentData.description,
      provider: this.paqueteriaData.proveedor,
      apiProvider: this.shipmentData.apiProvider,
      idService: this.paqueteriaData.idServicio,
      isInternational: this.shipmentData.isInternational || false
    };
    console.log(this.shipmentData.description);
    localStorage.removeItem('fromData');
    localStorage.removeItem('toData');
    console.log('Sending shipment data:', newShipment);
    this.shipmentService.createShipment(newShipment, this.userId).subscribe(
      response => {
        console.log('Shipment created successfully', response);
        console.log('Folio:', response.shipment);
        this.folio = response.shipment;
        this.router.navigate(['/pay-shipments']);
      },
      error => {
        console.error('Error creating shipment', error);
        // Add error handling here, like showing an error message to the user
      }
    );
  }

  updateDescription(newDescription: string) {
    this.shipmentData.description = newDescription;
  }

  formatCurrency(amount: number): string {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
  }

  hasSelectedPackage(): boolean {
    return this.selectedPackage !== null;
  }

  openEditForm(type: 'remitente' | 'destinatario') {
    this.selectedFormType = type;
    if (type === 'remitente') {
      this.editForm = { ...this.fromData };
    } else {
      this.editForm = { ...this.toData };
    }
    this.isEditModalOpen = true;
  }

  closeEditModal() {
    this.isEditModalOpen = false;
    this.editForm = {};
  }

  saveEditedAddress() {
    if (this.selectedFormType === 'remitente') {
      this.fromData = { ...this.fromData, ...this.editForm };
    } else {
      this.toData = { ...this.toData, ...this.editForm };
    }

    // Update the shipment data
    this.shipmentData = {
      ...this.shipmentData,
      from: this.fromData,
      to: this.toData
    };

    // Close the modal
    this.closeEditModal();
  }
}