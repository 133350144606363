import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-shipments-details',
  templateUrl: './shipments-details.component.html',
  styleUrls: ['./shipments-details.component.scss']
})
export class ShipmentsDetailsComponent implements OnInit {
  shipmentId!: string;
  shipmentDetails: any;
  trackingDetails: any;
  paginatedTrackingDetails: any[] = [];
  itemsPerPage = 5;
  currentPage = 1;
  math = Math;
  showCancelDialog = false;
  cancellationReason = '';
  showSuccessAlert = false;
  showErrorAlert = false;
  alertMessage = '';
  isGeneratingGuide = false;
  showBotInstructions = false;

  constructor(
    private route: ActivatedRoute,
    private shipmentsService: ShipmentsService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.shipmentId = params['id'];      
      this.loadShipmentDetails();
      this.loadTracking();
    });
  }

  viewGuide() {
    if (this.shipmentDetails && this.shipmentDetails.guide_url) {
      window.open(this.shipmentDetails.guide_url, '_blank');
    } else {
      this.showErrorAlert = true;
      this.alertMessage = 'La guía no está disponible.';
    }
  }

  downloadReceipt() {
    this.generateReceipt();
  }

  generateReceipt() {
    const doc = new jsPDF({
      format: 'a6',
      unit: 'mm',
      orientation: 'portrait'
    });

    const drawLine = (y: number) => doc.line(5, y, 143, y);

    const logoUrl = '../../../assets/images/dagPacket-logo.png';
    doc.addImage(logoUrl, 'PNG', 5, 5, 40, 10);

    doc.setFontSize(8);
    doc.setTextColor(100, 100, 100);
    doc.text('TU ALIADO EN LOGÍSTICA', 50, 10);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('helvetica', 'bold');
    doc.text('RECIBO DE ENVÍO', 50, 15);
    doc.setFontSize(6);
    doc.setFont('helvetica', 'normal');
    doc.text(`Fecha: ${new Date().toLocaleDateString()}`, 143, 7, { align: 'right' });

    drawLine(18);

    doc.setFontSize(8);
    doc.setFont('helvetica', 'bold');
    doc.text('Origen:', 5, 22);
    doc.text('Destino:', 75, 22);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(7);
    doc.text(this.shipmentDetails.from.name, 5, 26);
    doc.text(this.shipmentDetails.to.name, 75, 26);
    doc.text(`Tel: ${this.shipmentDetails.from.phone}`, 5, 30);
    doc.text(`Tel: ${this.shipmentDetails.to.phone}`, 75, 30);
    doc.text(this.shipmentDetails.from.street, 5, 34);
    doc.text(this.shipmentDetails.to.street, 75, 34);
    doc.text(`CP: ${this.shipmentDetails.from.zip_code}`, 5, 38);
    doc.text(`CP: ${this.shipmentDetails.to.zip_code}`, 75, 38);

    drawLine(42);

    doc.setFontSize(7);
    doc.text(`Seguro: ${this.shipmentDetails.insurance.$numberDecimal > 0 ? 'Asegurado' : 'No asegurado'}`, 5, 46);
    doc.text(`Código de rastreo: ${this.shipmentDetails.trackingNumber}`, 5, 50);
    doc.text(`${this.shipmentDetails.provider}: ${this.shipmentDetails.guide_number || 'N/A'}`, 5, 54);

    drawLine(58);

    doc.setFontSize(6);
    doc.text('Rastreo: https://dagpacket/plataforma-web.app/rastreo', 5, 62);
    doc.text(`Facturación: https://factura.dagpacket.org (Código: ${this.shipmentDetails.trackingNumber})`, 5, 66);

    drawLine(70);

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(`Total a pagar: $${this.shipmentDetails.price.$numberDecimal}`, 5, 75);    

    drawLine(78);

    doc.setFontSize(6);
    doc.setFont('helvetica', 'normal');
    doc.text('El firmante declara que la mercancía es legal y de procedencia lícita.', 5, 82);
    doc.text('DAGPACKET no asume responsabilidad por el contenido del paquete.', 5, 85);
  
    const rectWidth = 95;
    const rectX = (doc.internal.pageSize.width - rectWidth) / 2;
    doc.rect(rectX, 88, rectWidth, 5);
    doc.text('Nombre y Firma Cliente', doc.internal.pageSize.width / 2, 96, { align: 'center' });
  
    doc.setFontSize(8);
    doc.setFont('helvetica', 'bold');
    doc.text('GRACIAS POR SU ENVÍO', doc.internal.pageSize.width / 2, 105, { align: 'center' });
  
    doc.save(`recibo_${this.shipmentDetails.trackingNumber}.pdf`);
  }

  loadShipmentDetails() {
    this.shipmentsService.getShipmentDetails(this.shipmentId).subscribe(
      res => {
        this.shipmentDetails = res.data;        
      },
      error => {
        console.error('Error loading shipment details', error);
        this.showErrorAlert = true;
        this.alertMessage = 'Error al cargar los detalles del envío';
      }
    );
  }

  loadTracking() {
    this.shipmentsService.getShipmentTracking(this.shipmentId).subscribe(
      res => {
        this.trackingDetails = res.data;
        this.updatePaginatedTrackingDetails();
      },
      error => {
        console.error('Error loading shipment tracking', error);
        this.showErrorAlert = true;
        this.alertMessage = 'Error al cargar el seguimiento del envío';
      }
    );
  }

  updatePaginatedTrackingDetails() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedTrackingDetails = this.trackingDetails.slice(startIndex, endIndex);
  }

  nextPage() {
    if (this.currentPage * this.itemsPerPage < this.trackingDetails.length) {
      this.currentPage++;
      this.updatePaginatedTrackingDetails();
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedTrackingDetails();
    }
  }

  openCancelDialog() {
    this.showCancelDialog = true;
  }

  closeCancelDialog() {
    this.showCancelDialog = false;
    this.cancellationReason = '';
    this.resetAlerts();
  }

  createCancellationRequest(cancellationForm: NgForm) {
    if (cancellationForm.valid) {
      this.cancellationService.createCancellationRequest(cancellationForm.value).subscribe(
        (res) => {
          if (res.success) {
            this.showSuccessAlert = true;
            this.alertMessage = 'Solicitud de cancelación enviada con éxito';
            setTimeout(() => this.closeCancelDialog(), 1000);
          } else {
            this.showErrorAlert = true;
            this.alertMessage = res.message || 'Error al procesar la solicitud de cancelación';
          }
        },
        (error) => {
          console.error('Error en la solicitud de cancelación:', error);
          this.showErrorAlert = true;
          this.alertMessage = 'Error al procesar la solicitud de cancelación';
        }
      );
    } else {
      this.showErrorAlert = true;
      this.alertMessage = 'Por favor, complete todos los campos requeridos';
    }
  }

  getPaymentStatusClass(status: string): string {
    switch (status) {
      case 'Pagado': return 'bg-green-500';
      case 'Pendiente': return 'bg-orange-500';
      case 'Cancelado': return 'bg-red-600';
      case 'Reembolsado': return 'bg-blue-500';
      default: return 'bg-gray-500';
    }
  }

  resetAlerts() {
    this.showSuccessAlert = false;
    this.showErrorAlert = false;
    this.alertMessage = '';
  }
  
  toggleBotInstructions() {
    this.showBotInstructions = !this.showBotInstructions;
  }
}
