import { Component } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-locker-recive',
  templateUrl: './locker-recive.component.html',
  styleUrls: ['./locker-recive.component.scss']
})
export class LockerReciveComponent {

  qrscan_lottie: AnimationOptions = {
    path: '../../../assets/locker_images/qrscan.json', // Ruta a tu archivo JSON
  };

}
