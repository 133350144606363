<div class="details-container">
    <div *ngIf="userInfo; else loading">
      <h1>Información del Usuario</h1>
      <p><strong>Nombre:</strong> {{ userInfo.name }}</p>
      <p><strong>Email:</strong> {{ userInfo.email }}</p>
      <p><strong>Teléfono:</strong> {{ userInfo.phone }}</p>
    </div>
  
    <ng-template #loading>
      <p *ngIf="!errorMessage">Cargando información del usuario...</p>
      <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    </ng-template>
  </div>
  