<div class="min-h-screen flex items-center justify-center flex-col">
    <h1 class="text-2xl font-bold mb-4">Gavetas Log</h1>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead class="text-xs uppercase bg-dagblue text-white">
                <tr>
                    <th scope="col" class="px-6 py-3">No Gaveta</th>
                    <th scope="col" class="px-6 py-3">Acción</th>
                    <th scope="col" class="px-6 py-3">delivery</th>
                    <th scope="col" class="px-6 py-3">Fecha y Hora</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="paginatedGavetas.length === 0">
                    <td colspan="7" class="text-center px-6 py-4 text-gray-500">No hay datos disponibles</td>
                </tr>
                <tr *ngFor="let gaveta of paginatedGavetas" class="bg-white border-b hover:bg-gray-50">
                    <td class="px-6 py-4">{{ gaveta.gabeta_id }}</td>
                    <td class="px-6 py-4">{{ gaveta.action }}</td>
                    <td class="px-6 py-4">{{ gaveta.delivery ? gaveta.delivery : 'Ninguno'}}</td>
                    <td class="px-6 py-4">{{ gaveta.date_time | date: 'short' }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Botón para volver a la página anterior -->
    <div class="mt-4">
        <button (click)="goBack()" class="bg-gray-500 text-white px-4 py-2 rounded">Volver</button>
    </div>

    <!-- Controles de Paginación -->
    <div class="mt-4">
        <button (click)="previousPage()" [disabled]="currentPage === 1" class="bg-dagblue text-white px-4 py-2 rounded">Anterior</button>
        <span class="mx-2">Página {{ currentPage }} de {{ totalPages }}</span>
        <button (click)="nextPage()" [disabled]="currentPage === totalPages" class="bg-dagblue text-white px-4 py-2 rounded">Siguiente</button>
    </div>
</div>
