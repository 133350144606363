// tts.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TtsService {
  private synth: SpeechSynthesis;

  constructor() {
    this.synth = window.speechSynthesis;
  }

  speak(text: string, lang: string = 'es-MX'): void {
    this.stop(); // Detiene cualquier habla en curso

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = lang;
    utterance.pitch = 1;
    utterance.rate = 1;
    utterance.volume = 1;

    this.synth.speak(utterance);
  }

  stop(): void {
    if (this.synth.speaking) {
      this.synth.cancel();
    }
  }
}