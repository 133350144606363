import { Component, OnInit } from '@angular/core';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';

interface PackingItem {
  packing_id: {
    _id: string;
    name: string;
    sell_price: {
      $numberDecimal: string;
    };
    imageUrl: string;
    description: string;
    type: string;
  };
  quantity: number;
  last_restock_date: Date;
}

interface InventoryResponse {
  success: boolean;
  message: string;
  data: [{
    inventory: PackingItem[];
  }];
}

@Component({
  selector: 'app-user-packing',
  templateUrl: './user-packing.component.html',
  styleUrls: ['./user-packing.component.scss']
})
export class UserPackingComponent implements OnInit {
  paginatedPacking: any[] = [];
  allPacking: any[] = [];
  filteredPacking: any[] = [];
  startDate: string = '';
  endDate: string = '';
  userId: any;  

  showRequestStockModal: boolean = false;
  selectedProduct: string = '';
  requestQuantity: number = 1;
  
  currentPage: number = 1;
  pageSize: number = 5;
  totalItems: number = 0;
  Math: any = Math;

  allAvailablePacking: any[] = [];

  constructor(
    private packingService: ShipmentsService, 
    private authService: AuthLoginService,
    private refillRequestService: RefillRequestService,
    private shipmentsService: ShipmentsService // Inyecta el servicio de reabastecimiento
  ) {
    this.Math = Math;
  }

  ngOnInit(): void {
    this.userId = this.authService.getId();
    this.loadPacking();
    this.loadAllAvailablePacking();
  }

  openRequestStockDialog() {
    this.showRequestStockModal = true;
  }

  closeRequestStockModal() {
    this.showRequestStockModal = false;
    this.selectedProduct = '';
    this.requestQuantity = 1;
  }

  loadAllAvailablePacking(): void {
    this.shipmentsService.getAllPackingList().subscribe(
      (response: any) => {
        if (response.success) {
          this.allAvailablePacking = response.data.packings.map((item: any) => ({
            _id: item._id,
            name: item.name,
            image: item.image, // Asumiendo que la imagen viene en base64
            type: item.type,
            description: item.description
          }));
        } else {
          console.error('Error loading all available packing:', response.message);
        }
      },
      (error) => {
        console.error('Error loading all available packing:', error);
      }
    );
  }

  submitStockRequest() {
    if (this.selectedProduct && this.requestQuantity > 0) {
      this.refillRequestService.createRefillRequest({
        userId: this.userId,
        packingId: this.selectedProduct,
        quantityRequested: this.requestQuantity
      }).subscribe(
        (response) => {
          if (response.success) {
            console.log('Solicitud de reabastecimiento creada con éxito');
            this.closeRequestStockModal();
            this.loadPacking(); // Recargar el inventario del usuario
          } else {
            console.error('Error al crear la solicitud de reabastecimiento:', response.message);
          }
        },
        (error) => {
          console.error('Error al crear la solicitud de reabastecimiento:', error);
        }
      );
    } else {
      console.error('Producto no seleccionado o cantidad inválida');
    }
  }

  loadPacking(): void {
    this.packingService.getPackingList().subscribe(
      (res: InventoryResponse) => {
        if (res.success && res.data && res.data.length > 0) {
          this.allPacking = res.data[0].inventory.map((item: PackingItem) => ({
            _id: item.packing_id._id,
            type: item.packing_id.type,
            name: item.packing_id.name,
            price: parseFloat(item.packing_id.sell_price.$numberDecimal),
            image: item.packing_id.imageUrl,
            quantity: item.quantity,
            description: item.packing_id.description,
            last_restock_date: item.last_restock_date
          }));
          this.filteredPacking = [...this.allPacking];
          this.updatePaginatedPacking();
        }
      },
      (error) => {
        console.error('Error al cargar la lista de embalajes:', error);
        // Aquí puedes añadir una notificación de error
      }
    );
  }

  applyDateFilter(): void {
    if (this.startDate && this.endDate) {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      endDate.setHours(23, 59, 59); // Set to end of day

      this.filteredPacking = this.allPacking.filter(item => {
        const itemDate = new Date(item.last_restock_date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    } else {
      this.filteredPacking = [...this.allPacking];
    }
    this.currentPage = 1;
    this.updatePaginatedPacking();
  }

  clearDateFilter(): void {
    this.startDate = '';
    this.endDate = '';
    this.filteredPacking = [...this.allPacking];
    this.currentPage = 1;
    this.updatePaginatedPacking();
  }

  updatePaginatedPacking() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.filteredPacking.length);
    this.paginatedPacking = this.filteredPacking.slice(startIndex, endIndex);
    this.totalItems = this.filteredPacking.length;
  }

  get totalPages(): number {
    return Math.max(1, Math.ceil(this.totalItems / this.pageSize));
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updatePaginatedPacking();
    }
  }

  pageRange(): number[] {
    const range = 2;
    const start = Math.max(1, this.currentPage - range);
    const end = Math.min(this.totalPages, this.currentPage + range);
    return Array.from({length: (end - start + 1)}, (_, i) => start + i);
  }
}