import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { QuotesService } from 'src/app/_services/quotes/quotes.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { CashRegisterService } from 'src/app/_services/Cashiers/cash-register.service';

interface Shipment {
  _id: string;
  trackingNumber: string;
  price: { $numberDecimal: string };
  selected: boolean;
  // Add other properties as needed
}

@Component({
  selector: 'app-pay-shipments',
  templateUrl: './pay-shipment.component.html',
  styleUrls: ['./pay-shipment.component.scss']
})
export class PayShipmentComponent implements OnInit {
  pendingShipments: any[] = [];
  selectedPaymentMethod: string = '';
  transactionNumber: string = '';
  isProcessing: boolean = false;
  generatingGuides: boolean = false;
  guidesGenerated: number = 0;
  totalGuides: number = 0;
  errorMessage: string = '';
  successMessage: string = '';
  showToast: boolean = false;

  constructor(
    private shipmentsService: ShipmentsService,
    private quotesService: QuotesService,
    private authService: AuthLoginService,
    private router: Router,
    private sharedDataService: SharedDataService,
    private cashRegisterService: CashRegisterService
  ) {}

  ngOnInit() {
    this.loadPendingShipments();
  }

  loadPendingShipments() {
    this.shipmentsService.getPendingShipments().subscribe(
      (response) => {
        this.pendingShipments = response.data.map((shipment: Shipment) => ({...shipment, selected: false}));
      },
      (error) => {
        console.error('Error loading pending shipments', error);
        this.errorMessage = 'Error al cargar los envíos pendientes';
      }
    );
  }

  selectPaymentMethod(method: string) {
    this.selectedPaymentMethod = method;
    if (!this.isCardPayment()) {
      this.transactionNumber = '';
    }
  }

  isCardPayment(): boolean {
    return this.selectedPaymentMethod === 'td-debito' || this.selectedPaymentMethod === 'td-credito';
  }

  updateSelectedShipments() {
    // This method is called whenever a checkbox is checked/unchecked
    // You can perform any additional logic here if needed
  }

  getSelectedShipments(): any[] {
    return this.pendingShipments.filter(shipment => shipment.selected);
  }

  calculateSelectedTotal(): number {
    return this.getSelectedShipments().reduce((total, shipment) => 
      total + parseFloat(shipment.price.$numberDecimal), 0);
  }

  processPaymentAndGenerateGuides() {
    if (!this.validatePayment()) {
      return;
    }

    this.isProcessing = true;
    this.generatingGuides = true;
    this.resetMessages();

    const selectedShipments = this.getSelectedShipments();
    const shipmentIds = selectedShipments.map(shipment => shipment._id);
    this.totalGuides = shipmentIds.length;
    this.guidesGenerated = 0;

    this.generateGuides(selectedShipments).pipe(
      mergeMap(guideResults => {
        if (guideResults.every(result => result.success)) {
          return this.getCurrentCashRegisterId().pipe(
            mergeMap(cashRegisterId => this.performPayment(shipmentIds, cashRegisterId))
          );
        } else {
          throw new Error('No se pudieron generar todas las guías.');
        }
      })
    ).subscribe(
      (response) => this.handlePaymentResponse(response),
      (error) => this.handlePaymentError(error)
    );
  }

  cancelSelectedShipments() {
    const selectedShipments = this.getSelectedShipments();
    if (selectedShipments.length === 0) {
      this.errorMessage = 'No hay envíos seleccionados para cancelar';
      return;
    }

    this.isProcessing = true;
    this.resetMessages();

    const shipmentIds = selectedShipments.map(shipment => shipment._id);
    
    // Assuming the cancelShipments method is added to the ShipmentsService
    this.shipmentsService.cancelShipments(shipmentIds).subscribe(
      (response: any) => {
        this.isProcessing = false;
        if (response.success) {
          this.successMessage = 'Envíos cancelados exitosamente';
          this.loadPendingShipments(); // Reload the list of pending shipments
        } else {
          this.errorMessage = response.message || 'Error al cancelar los envíos';
        }
      },
      (error: any) => {
        this.isProcessing = false;
        this.errorMessage = 'Error al cancelar los envíos: ' + (error.error?.message || error.message || 'Ocurrió un error desconocido');
        console.error('Error completo:', error);
      }
    );
  }

  private generateGuides(shipments: any[]): Observable<any[]> {
    const guideRequests = shipments.map(shipment => 
      this.quotesService.generateAndSaveGuide(shipment).pipe(
        map(response => {
          if (response.success && response.saveSuccess) {
            this.guidesGenerated++;
            return {
              success: true,
              shipmentId: shipment._id,
              guideUrl: response.data.guideUrl,
              guideNumber: response.data.guideNumber
            };
          } else {
            console.error('Error generating or saving guide for shipment', shipment._id, response);
            return {
              success: false,
              shipmentId: shipment._id,
              error: response.message || 'Error generating or saving guide'
            };
          }
        }),
        catchError(error => {
          console.error('Error in guide generation process for shipment', shipment._id, error);
          return of({
            success: false,
            shipmentId: shipment._id,
            error: 'Error in guide generation process'
          });
        })
      )
    );

    return forkJoin(guideRequests);
  }

  private validatePayment(): boolean {
    if (!this.selectedPaymentMethod) {
      this.errorMessage = 'Por favor, seleccione un método de pago';
      return false;
    }

    if (this.isCardPayment() && !this.transactionNumber) {
      this.errorMessage = 'Por favor, ingrese el número de transacción';
      return false;
    }

    if (this.getSelectedShipments().length === 0) {
      this.errorMessage = 'Por favor, seleccione al menos un envío para pagar';
      return false;
    }

    return true;
  }

  private resetMessages() {
    this.errorMessage = '';
    this.successMessage = '';
  }

  private getCurrentCashRegisterId(): Observable<string | null> {
    return this.cashRegisterService.getCurrentCashRegister().pipe(
      map(cashRegister => {
        console.log('Cash register received:', cashRegister);
        return cashRegister ? cashRegister._id : null;
      }),
      catchError(error => {
        console.error('Error al obtener la caja actual:', error);
        return of(null);
      })
    );
  }

  private performPayment(shipmentIds: string[], cashRegisterId: string | null): Observable<any> {
    return this.shipmentsService.payShipments(
      shipmentIds,
      this.selectedPaymentMethod,
      this.transactionNumber || null,
      cashRegisterId
    );
  }

  private handlePaymentResponse(response: any) {
    this.isProcessing = false;
    this.generatingGuides = false;
    if (response.success) {
      this.successMessage = `Guías generadas: ${this.guidesGenerated}/${this.totalGuides}. Pago procesado exitosamente.`;
      this.showToast = true;
      this.clearStoredData();
      setTimeout(() => {
        this.router.navigate(['/quote']);
      }, 3000);
    } else {
      this.errorMessage = response.message || 'Error al procesar el pago';
    }
  }

  private handlePaymentError(error: any) {
    this.isProcessing = false;
    this.generatingGuides = false;
    if (error.message === 'No se pudieron generar todas las guías.') {
      this.errorMessage = `Se generaron ${this.guidesGenerated} de ${this.totalGuides} guías. No se pudo procesar el pago.`;
    } else {
      this.errorMessage = 'Error al procesar el pago: ' + (error.error?.message || error.message || 'Ocurrió un error desconocido');
    }
    console.error('Error completo:', error);
  }

  private clearStoredData() {
    this.sharedDataService.clearData();
    console.log('Datos de envío borrados del caché');
  }
  
}