import { Component } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-locker-screenv2',
  templateUrl: './locker-screenv2.component.html',
  styleUrls: ['./locker-screenv2.component.scss']
})
export class LockerScreenv2Component {

  box_lottie: AnimationOptions = {
    path: '../../../assets/locker_images/animation.json', // Ruta a tu archivo JSON
  };

  band_lottie: AnimationOptions = {
    path: '../../../assets/locker_images/band.json', // Ruta a tu archivo JSON
  };

  shipping_lottie: AnimationOptions = {
    path: '../../../assets/locker_images/shipping.json', // Ruta a tu archivo JSON
  };

  packing_lottie: AnimationOptions = {
    path: '../../../assets/locker_images/packagelottie.json', // Ruta a tu archivo JSON
  };

  bills_lottie: AnimationOptions = {
    path: '../../../assets/locker_images/bills.json', // Ruta a tu archivo JSON
  };

  onAnimate(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
