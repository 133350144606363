import { Component, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';
import { RechargeRequestService } from 'src/app/_services/Rechargues/rechargue-request.service';
import { NgForm } from '@angular/forms';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import Swal from 'sweetalert2';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';




@Component({
  selector: 'app-licensees-dashboard',
  templateUrl: './licensees-dashboard.component.html',
  styleUrls: ['./licensees-dashboard.component.scss']
})
export class LicenseesDashboardComponent implements OnInit {
  public chart: Chart | undefined;
  userProfit: number = 0;
  ProfitPacking: number = 0;
  isShipmentsOpen = true;
  isPackingOpen = false;
  isServicesOpen = false;
  isRechargesOpen = false;
  userid: any;
  isAddFundsModalOpen = false;
  user: any;
  data: any = {
    amount: null,
    referenceNumber: '',
    paymentMethod: 'transferencia',
    proofImage: null,
    rechargeType: ''
  };

  showToast = false;
  toastMessage = '';
  toastType: 'success' | 'error' = 'success';

  isAdmin: boolean = false;
  users: any[] = [];
  selectedUserId: any;


  transactionHistory: any[] = [];
  showTransactionHistoryModal: boolean = false;
  currentPageTransaction: number = 1;
  totalPagesTransaction: number = 0;
  pageSize: number = 10;
  totalItemsTransaction: number = 0;
  hasNextPage: boolean = false;
  hasPreviousPage: boolean = false;

  // Nuevas propiedades para la utilidad quincenal
  selectedYear: number = new Date().getFullYear();
  selectedMonth: number = new Date().getMonth() + 1;
  selectedQuincena: 1 | 2 = 1;
  quincenalProfit: any = null;  
  loadingProfit: boolean = false;
  errorLoadingProfit: string | null = null;
  enteredPin: string = '';
  storedPin: string = '';
  showPinModal: boolean = false;
  pendingAction: Function | null = null;
  profitData: any;

  constructor(
    private userService: UserServiceService,
    private authService: AuthLoginService,
    private rechargeRequestService: RechargeRequestService,
    private profitService: ShipmentsService, // Nuevo servicio
    private transactionService: TransactionService // Servicio de transacciones
  ) {
    this.userid = this.authService.getId();
  }

  ngOnInit(): void {
    Chart.register(...registerables);
    this.loadUserProfile();
    this.loadQuincenalProfit(); // Cargar datos de utilidad quincenal
    this.getUserPin(); 
    this.fetchQuincenalProfit();
  }

  // Obtén el NIP del usuario
  getUserPin(): void {
    this.authService.userProfile().subscribe(
      (res) => {
        this.storedPin = res.data.pin;
      },
      (error) => {
        console.log('Error al obtener el perfil del usuario', error);
      }
    );
  }

  // Abre el modal de NIP y establece la acción pendiente
  openPinModal(action: Function): void {
    this.enteredPin = '';
    this.pendingAction = action;
    this.showPinModal = true;
  }

  // Cierra el modal de NIP
  closePinModal(): void {
    this.showPinModal = false;
    this.enteredPin = '';
    this.pendingAction = null;
  }

  // Valida el NIP y ejecuta la acción pendiente si es correcto
  validateNip(): void {
    console.log("NIP ingresado:", this.enteredPin);
    console.log("NIP almacenado:", this.storedPin);

    if (this.enteredPin === this.storedPin) {
      this.showPinModal = false;
      if (this.pendingAction) this.pendingAction(); // Ejecuta la acción pendiente
    } else {
      Swal.fire({
        icon: 'error',
        title: 'NIP incorrecto',
        text: 'El NIP ingresado no es correcto.',
      });
    }
  }

  openPinModalForAddFunds(): void {
    this.openPinModal(() => this.openAddFundsModal());
  }

  private loadUserProfile(): void {
    this.authService.userProfile().subscribe(
      (response) => {
        this.user = response.data;
        if (!this.user.wallet) {
          this.user.wallet = {
            sendBalance: { $numberDecimal: '0' },
            servicesBalance: { $numberDecimal: '0' },
            rechargeBalance: { $numberDecimal: '0' }
          };
        }
        this.isAdmin = response.data.role === 'ADMIN';
        this.userid = this.isAdmin ? this.selectedUserId || this.userid : this.userid;
        this.loadUsers();
        this.loadStatistics();
      },
      (error) => {
        console.error('Error loading user profile:', error);
      }
    );
  }

  private loadUsers(): void {
    if (this.isAdmin) {
      this.userService.listUsers(1, 100).subscribe(
        (response) => {
          this.users = response.data.users;
          if (this.selectedUserId) {
            this.userid = this.selectedUserId;
          } else {
            this.selectedUserId = this.userid;
          }
          this.loadStatistics();
        },
        (error) => {
          console.error('Error loading users:', error);
        }
      );
    }
  }

  onUserChange(): void {
    this.userid = this.selectedUserId;
    this.loadStatistics();
    this.loadQuincenalProfit();
  }

  private loadStatistics(): void {
    this.userService.shipmentProfit(this.userid).subscribe(
      (res) => {
        this.userProfit = Number(res.data.currentMonthProfit.$numberDecimal);
        console.log('Profit:', this.userProfit);
      },
      (error) => {
        console.error('Error loading shipment profit:', error);
      }
    );

    this.userService.getProfitPacking(this.userid).subscribe(
      (res) => {
        this.ProfitPacking = Number(res.message.totalPackingCost.$numberDecimal);
        console.log('Profit Packing:', this.ProfitPacking);
      },
      (error) => {
        console.error('Error loading packing profit:', error);
      }
    );
  }

  loadQuincenalProfit(): void {
    this.loadingProfit = true;
    this.errorLoadingProfit = null;
    this.profitService.getQuincenalProfit(this.userid, this.selectedYear, this.selectedMonth, this.selectedQuincena)
      .subscribe(
        (response) => {
          this.loadingProfit = false;
          if (response.success) {
            this.quincenalProfit = response.data;
            this.updateProfitChart();
          } else {
            this.errorLoadingProfit = response.message || 'Error al cargar los datos de utilidad';
            this.showToastMessage(this.errorLoadingProfit, 'error');
          }
        },
        (error) => {
          this.loadingProfit = false;
          this.errorLoadingProfit = 'Error al conectar con el servidor. Por favor, intente más tarde.';
          console.error('Error loading quincenal profit:', error);
          this.showToastMessage(this.errorLoadingProfit, 'error');
        }
      );
  }

  extractProfitValue(profit: any): number {
    if (typeof profit === 'number') {
      return profit;
    } else if (profit && profit.$numberDecimal) {
      return parseFloat(profit.$numberDecimal);
    }
    return 0;
  }

  getProfitValue(profit: any): number {
    if (typeof profit === 'number') {
      return profit;
    } else if (profit && profit.$numberDecimal) {
      return parseFloat(profit.$numberDecimal);
    }
    return 0; // Valor por defecto si no hay datos
  }
  
  updateProfitChart(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  
    if (!this.quincenalProfit) {
      console.log('No hay datos de utilidad para mostrar en el gráfico');
      return;
    }
  
    const ctx = document.getElementById('profitChart') as HTMLCanvasElement;
    const dataValues = [
      this.getProfitValue(this.quincenalProfit.shipmentProfit),
      this.getProfitValue(this.quincenalProfit.packingProfit),
      this.getProfitValue(this.quincenalProfit.servicesProfit),
      this.getProfitValue(this.quincenalProfit.profitData), 
    ];
  
    console.log('Datos para el gráfico:', dataValues);
  
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Envíos', 'Empaques', 'Servicios', 'Recargas'],
        datasets: [{
          label: 'Utilidad Quincenal',
          data: dataValues,
          backgroundColor: (context: any) => {
            const value = context.raw;
            return value >= 0 ? 'rgba(75, 192, 192, 0.2)' : 'rgba(255, 99, 132, 0.2)';
          },
          borderColor: (context: any) => {
            const value = context.raw;
            return value >= 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)';
          },
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}`, // Formatea los valores en el eje Y
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                const value = context.raw;
                return `Utilidad: ${value >= 0 ? '+' : ''}${value}`;
              }
            }
          }
        }
      }
    });
  }
  
  
  
  

  onQuincenaChange(): void {
    this.loadQuincenalProfit();
  }

  showShipments(): void { this.setActiveTab('shipments'); }
  showPacking(): void { this.setActiveTab('packing'); }
  showServices(): void { this.setActiveTab('services'); }
  showRecharges(): void { this.setActiveTab('recharges'); }

  private setActiveTab(tab: string): void {
    this.isShipmentsOpen = tab === 'shipments';
    this.isPackingOpen = tab === 'packing';
    this.isServicesOpen = tab === 'services';
    this.isRechargesOpen = tab === 'recharges';
  }

  formatProfit(profit: any): string {
    if (typeof profit === 'number') {
      return `$${profit.toFixed(2)}`;
    } else if (profit && profit.$numberDecimal) {
      return `$${parseFloat(profit.$numberDecimal).toFixed(2)}`;
    }
    return '$0.00';
  }

  openAddFundsModal(): void {
    this.isAddFundsModalOpen = true;
  }

  validateAmount(): void {
    if (this.data.amount !== null) {
      this.data.amount = Math.max(0, this.data.amount);
    }
  }

  onFileSelected(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.data.proofImage = file;
    }
  }

  closeAddFundsModal(): void {
    this.isAddFundsModalOpen = false;
    this.resetForm();
  }

  submitAddFunds(form: NgForm): void {    
    if (form.valid && this.data.rechargeType) {
      this.rechargeRequestService.createRechargeRequest(this.data).subscribe(
        (res) => {
          if (res.success === true) {
            this.showToastMessage('Recarga solicitada con éxito', 'success');
            this.closeAddFundsModal();
          } else {
            this.showToastMessage('Error al solicitar la recarga: ' + res.message, 'error');
          }
        },
        (error) => {
          this.showToastMessage('Error en la solicitud de recarga: ' + error.message, 'error');
        }
      );
    } else {
      this.showToastMessage('El formulario no es válido. Por favor, revisa los campos y asegúrate de seleccionar un tipo de recarga.', 'error');
    }
  }

  private resetForm(): void {
    this.data = {
      amount: null,
      referenceNumber: '',
      paymentMethod: 'transferencia',
      proofImage: null,
      rechargeType: ''
    };
  }

  private showToastMessage(message: string, type: 'success' | 'error'): void {
    this.toastMessage = message;
    this.toastType = type;
    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 3000);
  }

  getPageNumbers(): number[] {
    return Array.from({ length: this.totalPagesTransaction }, (_, i) => i + 1);
  }
  
  showTransactionHistory() {
    this.isRechargesOpen = true;
    this.isShipmentsOpen = false;
    this.isPackingOpen = false;
    this.isServicesOpen = false;
    this.transactionService.getTransaccionByUser(this.userid, this.currentPageTransaction, this.pageSize).subscribe(
      (response) => {
        this.transactionHistory = response.message.transactions; // Datos específicos de recargas
        this.hasNextPage = response.message.hasNextPage;
        this.hasPreviousPage = response.message.hasPreviousPage;
        this.totalItemsTransaction = response.message.total;
        this.totalPagesTransaction = response.message.totalPages;
      },
      (error) => {
        console.error('Error al cargar el historial de transacciones:', error);
      }
    );
  }
  
  
  // Métodos de navegación para paginación
  nextPage() {
    if (this.hasNextPage) {
      this.currentPageTransaction++;
      this.showTransactionHistory();
    }
  }
  
  previousPage() {
    if (this.hasPreviousPage) {
      this.currentPageTransaction--;
      this.showTransactionHistory();
    }
  }
  
  goToPage(page: number) {
    this.currentPageTransaction = page;
    this.showTransactionHistory();
  }
  
  onPageSizeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.pageSize = +selectElement.value;
    this.currentPageTransaction = 1; // Resetear a la primera página con el nuevo tamaño
    this.showTransactionHistory();
  }
  
  cerrarModalTransactionHistory() {
    this.showTransactionHistoryModal = false;
  }

  fetchQuincenalProfit(): void {
    const userId = this.authService.getId(); 
    if (!userId) {
      console.error('No se encontró el ID del usuario.');
      return; 
    }
  
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1; 
    const quincena = 1; 
  
    this.transactionService.getQuincenalProfit(userId, year, month, quincena).subscribe({
      next: (data) => {
        console.log('Respuesta completa de utilidad quincenal:', data); 
        if (data?.message?.length > 0) {
          const firstMessage = data.message[0]; 
          const profitValue = parseFloat(firstMessage?.total?.$numberDecimal || '0');
          console.log('Valor de profitData:', profitValue); // Log para validar el valor exacto
          this.quincenalProfit = {
            ...this.quincenalProfit, 
            profitData: profitValue,
          };
        } else {
          console.warn('No se encontró información de utilidad quincenal.');
          this.quincenalProfit = {
            ...this.quincenalProfit,
            profitData: 0,
          };
        }
        this.updateProfitChart();
      },
      error: (error) => {
        console.error('Error al obtener la utilidad quincenal:', error);
        this.quincenalProfit = {
          ...this.quincenalProfit,
          profitData: 0,
        };
      }
    });
  }
  
  
  
  
}