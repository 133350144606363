<div class="flex flex-col justify-start items-center min-h-screen bg-white relative p-4">
    <div class="w-full max-w-[60vw] mt-[100px]">
        <div class="bg-gray-100 rounded-lg shadow-lg overflow-hidden">
            <div class="bg-dagpk text-white p-6">
                <h2 class="text-2xl font-bold">Cambiar la contraseña de tu cuenta</h2>
                <p class="text-sm mt-2 opacity-80">No lo compartas con nadie</p>
            </div>
            
            <form (ngSubmit)="changePassword()" class="p-6 space-y-6">
                <div class="space-y-4">
                    <div class="relative">
                        <input type="password" [(ngModel)]="currentPassword" name="currentPassword" placeholder="Contraseña actual" 
                               class="w-full px-4 py-3 border-2 rounded-full border-gray-300 text-gray-600 focus:outline-none focus:border-dagpk" 
                               required />
                        <button type="button" (click)="togglePasswordVisibility('current')" 
                                class="absolute top-1/2 right-3 text-gray-400 transform -translate-y-1/2">
                            <!-- Ícono de ojo aquí -->
                        </button>
                    </div>
                    
                    <div class="relative">
                        <input type="password" [(ngModel)]="newPassword" name="newPassword" placeholder="Nueva contraseña" 
                               class="w-full px-4 py-3 border-2 rounded-full border-gray-300 text-gray-600 focus:outline-none focus:border-dagpk" 
                               required />
                        <button type="button" (click)="togglePasswordVisibility('new')" 
                                class="absolute top-1/2 right-3 text-gray-400 transform -translate-y-1/2">
                            <!-- Ícono de ojo aquí -->
                        </button>
                    </div>
                    
                    <div class="relative">
                        <input type="password" [(ngModel)]="confirmPassword" name="confirmPassword" placeholder="Confirmar nueva contraseña" 
                               class="w-full px-4 py-3 border-2 rounded-full border-gray-300 text-gray-600 focus:outline-none focus:border-dagpk" 
                               required />
                        <button type="button" (click)="togglePasswordVisibility('confirm')" 
                                class="absolute top-1/2 right-3 text-gray-400 transform -translate-y-1/2">
                            <!-- Ícono de ojo aquí -->
                        </button>
                    </div>
                </div>

                <div class="flex justify-end">
                    <button type="submit" class="px-6 py-3 bg-dagyel text-white rounded-full font-bold hover:bg-yellow-600 transition duration-300 ease-in-out">
                        Cambiar contraseña
                    </button>
                </div>
            </form>
        </div>

        <div class="mt-6 text-center">
            <p class="text-sm text-gray-600">¿Necesitas ayuda? <a href="#" class="text-dagblue hover:underline">Contacta a soporte</a></p>
        </div>
    </div>
</div>

<div *ngIf="message" class="fixed top-4 right-4 p-4 rounded-lg shadow-lg z-50"
     [ngClass]="{'bg-green-500 text-white': success, 'bg-red-500 text-white': !success}">
    {{ message }}
</div>