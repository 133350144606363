<div class="flex flex-col justify-start items-center min-h-screen bg-white relative p-4">
    <div *ngIf="message" class="fixed top-4 right-4 p-3 text-white rounded-lg shadow-lg z-50"
         [ngClass]="{'bg-green-500 text-white': success, 'bg-red-500 text-white': error}">
        {{ message }}
    </div>

    <div class="w-full max-w-[536px] mt-[224.96px]">        
        <div class="flex justify-center mb-[53.95px]">
            <img src="../../../assets/images/LogotipoA.png" alt="DagPacket Logo" class="w-[530.09px]">
        </div>

        <div class="text-center mb-6">
            <h2 class="text-xl font-bold text-gray-700">Reestablece tu contraseña</h2>
            <p class="text-sm text-gray-600 mt-2">Ingresa tu correo electrónico, te enviaremos un enlace para reestablecer tu contraseña.</p>
        </div>

        <form (ngSubmit)="onSubmit()" class="space-y-4">
            <div class="space-y-4">
                <input type="email" [(ngModel)]="email" name="email" placeholder="Correo electrónico" 
                       class="w-full px-4 py-3 border-2 rounded-full border-gray-400 text-gray-600 focus:outline-none focus:border-dagpk" 
                       required />
            </div>

            <button type="submit" [disabled]="loading"
                    class="w-full py-3 rounded-full bg-dagpk text-white font-bold text-lg hover:bg-opacity-90">
                <span *ngIf="!loading">REESTABLECER</span>
                <app-loader *ngIf="loading"></app-loader>
            </button>
        </form>

        <div class="mt-6 text-center">
            <p class="text-sm text-gray-700">¿No tienes una cuenta?</p>
            <button [routerLink]="['/signup']" 
                    class="mt-4 w-full py-3 border-2 border-dagpk rounded-full text-dagpk font-bold text-lg hover:bg-dagpk hover:text-white transition duration-200">
                REGISTRATE
            </button>
        </div>

        <div class="mt-8 flex justify-start">
            <button [routerLink]="['/login']" class="text-dagblue flex items-center justify-center w-16 h-16 border-2 hover:bg-dagblue hover:text-white duration-200 border-dagblue rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
            </button>
        </div>  
    </div>
</div>