// Importar dependencias necesarias
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  // Servicio para registrar un nuevo cliente
  register(customer: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/customers/register`, customer);
  }

  // Servicio para iniciar sesión de un cliente
  login(email: string, password: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/customers/login`, { email, password });
  }

  // Servicio para obtener el perfil de un cliente
  getCustomerProfile(customerId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/customers/profile/${customerId}`);
  }

  // Servicio para obtener todos los clientes
  listCustomers(page: number, limit: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/customers/list?page=${page}&limit=${limit}`);
  }

  // Servicio para actualizar el perfil de un cliente
  updateCustomerProfile(customerId: string, customerData: Partial<any>): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/customers/update/${customerId}`, customerData);
  }

  // Servicio para eliminar un cliente
  deleteCustomer(customerId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/customers/delete/${customerId}`);
  }
}

 
