<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
  <div class="container mx-auto p-4">
    <div class="flex justify-between items-center mb-4">
      <label class="flex items-center font-bold mb-4">
        <input type="checkbox" class="mr-2" />
        Mostrar nuevo dashboard
      </label>
      <div class="flex space-x-4">
        <button (click)="toggleDownloadForm()" class="btn-action">
          Descargar Resumen Utilidades
        </button>
        <button class="btn-action">
          Reporte Wallets
        </button>
        <div class="flex items-center space-x-2 font-bold mb-4">
          <span>Datos obtenidos desde:</span>
          <input type="date" class="input-date" (change)="onDateChange($event)" />
          <button (click)="onApply()" class="btn-primary">Aplicar</button>
        </div>
      </div>
    </div>

    <div *ngIf="showDownloadForm" class="flex items-center space-x-4 card">
      <h2 class="text-center text-dagblue font-bold mb-4">Descargar Resumen Utilidades</h2>
      <div class="flex flex-col">
        <label class="text-gray-700">Desde</label>
        <input type="date" class="input-date">
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700">Hasta</label>
        <input type="date" class="input-date">
      </div>
      <div>
        <button class="btn-primary">Descargar</button>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div class="card" (click)="openCancellationModal()">
        <div class="card-icon">
          <i class="fas fa-times-circle"></i>
        </div>
        <h2 class="card-title">Envios para cancelación</h2>
        <p class="card-value">{{ totalCancellations }}</p>
        <p class="card-status text-red-500">Pendientes</p>
      </div>
      <div class="card" routerLink="/displayRefills">
        <div class="card-icon">
          <i class="fas fa-file-alt"></i>
        </div>
        <h2 class="card-title">Solicitudes Recargas</h2>
        <p class="card-value">5</p>
        <p class="card-status text-red-500">Pendientes</p>
      </div>
      <div class="card" (click)="openPedidoModal()">
        <div class="card-icon">
          <i class="fas fa-file-invoice"></i>
        </div>
        <h2 class="card-title">Pedidos de Factura</h2>
        <p class="card-value">1</p>
        <p class="card-status text-red-500">Pendientes</p>
      </div>
      
      <div class="card col-span-1 md:col-span-2" (click)="openEnviosModal()">
        <div class="card-icon">
          <i class="fas fa-shipping-fast"></i>
        </div>
        <h2 class="card-title">Envíos</h2>
        <p class="card-value">{{ totalShipments }}</p>
        <p class="card-status text-red-500 mt-2">Total de envíos:</p>
        <p class="card-value">${{ quincenalProfit.toFixed(2) }}</p> <!-- Muestra la utilidad quincenal -->
        <p class="card-status text-green-500">Utilidad esta quincena</p>
      </div>
      
      
      <div class="card col-span-1 md:col-span-2" (click)="openEmpaquesModal()">
        <div class="card-icon">
          <i class="fas fa-box-open"></i>
        </div>
        <h2 class="card-title">Empaques</h2>
        <p class="card-status text-green-500 mt-2">+250.0% Respecto al periodo anterior</p>
        <p class="card-value">$8.40</p>
        <p class="card-status text-green-500">Utilidad esta quincena</p>
      </div>
      
      <div class="card" (click)="openUserModal()">
        <div class="card-icon">
          <i class="fas fa-users"></i>
        </div>
        <h2 class="card-title">Usuarios</h2>
        <p class="card-value">{{ totalUsers }}</p>
      </div>
      <div class="card" (click)="openPagoSModal()">
        <div class="card-icon">
          <i class="fas fa-wallet"></i>
        </div>
        <h2 class="card-title">Pago servicios</h2>
        <p class="card-value">$16.80</p>
        <p class="card-status text-green-500">Utilidad esta quincena</p>
      </div>
      <div class="card" (click)="openPagoRModal()">
        <div class="card-icon">
          <i class="fas fa-credit-card"></i>
        </div>
        <h2 class="card-title">Pago recarga</h2>
        <p class="card-value">
          {{ profitData | currency:'USD':'symbol':'1.2-2' }}
        </p>
        <p class="card-status text-green-500">Utilidad esta quincena</p>
      </div>
      <div class="card" (click)="openRepartoMModal()">
        <div class="card-icon">
          <i class="fas fa-truck"></i>
        </div>
        <h2 class="card-title">Reparto multimarca</h2>
        <p class="card-value">36</p>
      </div>
      
      <div class="card" (click)="openCuponesModal()">
        <div class="card-icon">
          <i class="fas fa-tag"></i>
        </div>
        <h2 class="card-title">Cupones</h2>
        <p class="card-value">41</p>
      </div>
      <div class="card" (click)="openDireccionesModal()">
        <div class="card-icon">
          <i class="fas fa-map-marker-alt"></i>
        </div>
        <h2 class="card-title">Direcciones</h2>
        <p class="card-value">{{ totalDirecciones }}</p>
      </div>
      <div class="card" (click)="openRecotizcionesModal()">
        <div class="card-icon">
          <i class="fas fa-redo-alt"></i>
        </div>
        <h2 class="card-title">Recotizaciones</h2>
        <p class="card-value">7</p>
      </div>
    </div>
  </div>
</div>
<!-- Modal para mostrar la lista de usuarios -->
<div *ngIf="showUserModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold text-dagblue">Lista de Usuarios</h2>
        <button (click)="closeUserModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!-- Barra de búsqueda -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input type="text" [(ngModel)]="searchTerm" (input)="onSearch()" placeholder="Buscar usuario..." 
               class="flex-grow border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue mb-2 md:mb-0" />

        <div class="flex space-x-2 items-center">
          <button class="bg-white border-2 border-gray-400 rounded-full px-2 py-2">
            <svg class="h-6 w-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
              <path d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"/>
            </svg>
          </button>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table class="min-w-full bg-[#F0F0F0]">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th class="py-2 px-4 border-b">Nombre</th>
              <th class="py-2 px-4 border-b">Email</th>
              <th class="py-2 px-4 border-b">Rol</th>
              <th class="py-2 px-4 border-b">Wallet</th>
              <th class="py-2 px-4 border-b">Status</th>
              <th class="py-2 px-4 border-b">Transacciones</th>
            </tr>
          </thead>
          <tbody class="text-gray-500">
            <tr *ngFor="let user of filteredUsers" class="hover:bg-gray-200">
              <td class="py-2 px-4 border-b">{{ user.name }}</td>
              <td class="py-2 px-4 border-b">{{ user.email }}</td>
              <td class="py-2 px-4 border-b">{{ user.role }}</td>
              <td class="py-2 px-4 border-b"><a href="#" class="text-dagblue">Ver</a></td>
              <td class="py-2 px-4 border-b"><span class="text-green-500">Active</span></td>
              <td class="py-2 px-4 border-b">
                <button (click)="openTransactionsModal(user)" class="text-blue-500 hover:text-blue-700">Ver Transacciones</button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border-2 border-gray-400 rounded-full px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1" 
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages" 
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para mostrar las transacciones del usuario -->
<div *ngIf="showTransactionsModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-5xl w-full max-h-full overflow-y-auto">
    <div class="p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Transacciones de {{ selectedUser?.name }}
        </h3>
        <button (click)="closeTransactionsModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <!-- Ícono de cerrar (X) -->
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="overflow-auto">
        <table class="min-w-full divide-y divide-gray-200 table-auto">
          <thead class="bg-darkblue text-white">
            <tr>
              <th scope="col" class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
              <th scope="col" class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Monto</th>
              <th scope="col" class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descripción</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr *ngFor="let transaction of transactions">
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction.date }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction.amount }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal para mostrar la lista de envíos pendientes de cancelación -->
<div *ngIf="showCancellationModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-2xl font-bold text-gray-900">
          Envíos Pendientes de Cancelación
        </h3>
        <button (click)="closeCancellationModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="overflow-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <!-- <select id="filter" class="border-2 border-gray-400 rounded-full px-4 py-2">
              <option>Paquetería</option>
              <option>Fecha</option>
              <option>Costo</option>
              <option>Utilidad</option>
              <option>Precio</option>
              <option>U. licenciat.</option>
              <option>Origen</option>
              <option>Destino</option>
              <option>Estado de pago</option>
              <option>Estado de envío</option>
            </select> -->
            <input type="text" class="border-2 border-gray-400 rounded-full px-4 py-2" placeholder="Buscar..." />
          </div>
        </div>
        <table class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th scope="col" class="py-2 px-4 border-b">Usuario</th>
              <th scope="col" class="py-2 px-4 border-b">Motivo</th>
              <th scope="col" class="py-2 px-4 border-b">Paquetería</th>
              <th scope="col" class="py-2 px-4 border-b">Fecha</th>
              <th scope="col" class="py-2 px-4 border-b">Estado de envío</th>
              
            </tr>
          </thead>
          <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
            <tr *ngFor="let cancellation of cancellations; let i = index" class="hover:bg-gray-200">
              <td class="px-6 py-4 whitespace-nowrap">{{ cancellation.user_id.name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ cancellation.motive }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ cancellation.shipment_id.provider }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ cancellation.createdAt | date:'dd/MM/yyyy HH:mm' }}</td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span [ngClass]="{
                  'text-green-600': cancellation.status === 'Aprobado',
                  'text-yellow-500': cancellation.status === 'Rechazado'
              }" class="font-bold text-lg">
                {{ cancellation.status }}
              </span>
              
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border-2 border-gray-400 rounded-full px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1" 
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages" 
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para reportes de pago -->
<div *ngIf="showReporteModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <!-- Título y Cerrar -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Reportes de pago</h2>
        <button (click)="closeReporteModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <!-- Filtro y Tabla -->
      <div class="overflow-x-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <label for="filter" class="text-gray-700">Filtro</label>
            <select id="filter" class="border rounded px-4 py-2">
              <option>Nombre</option>
            </select>
            <input type="text" class="border rounded px-4 py-2" placeholder="Buscar..." />
          </div>
        </div>
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Folio</th>
              <th class="py-2 px-4 border-b">Usuario</th>
              <th class="py-2 px-4 border-b">Cajero</th>
              <th class="py-2 px-4 border-b">Fecha del pago</th>
              <th class="py-2 px-4 border-b">Fecha creación</th>
              <th class="py-2 px-4 border-b">Fecha autorización</th>
              <th class="py-2 px-4 border-b">Monto</th>
              <th class="py-2 px-4 border-b">Estado</th>
              <th class="py-2 px-4 border-b">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let reporte of reportes">
              <td class="py-2 px-4 border-b">{{ reporte.folio }}</td>
              <td class="py-2 px-4 border-b">
                <div class="flex items-center">
                  <img class="h-10 w-10 rounded-full mr-4" [src]="reporte.usuarioImagen" alt="{{ reporte.usuario }}">
                  <div>{{ reporte.usuario }}</div>
                </div>
              </td>
              <td class="py-2 px-4 border-b">{{ reporte.cajero }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaPago }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaCreacion }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaAutorizacion }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.monto | currency }}</td>
              <td class="py-2 px-4 border-b">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                  {{ reporte.estado }}
                </span>
              </td>
              <td class="py-2 px-4 border-b">
                <button class="bg-blue-500 text-white px-4 py-2 rounded" (click)="verDetalles(reporte)">Ver Detalles</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border rounded px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para pedidos de factura -->
<div *ngIf="showPedidoModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Rembolso</h2>
        <button (click)="closePedidoModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="overflow-x-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <label for="filter" class="text-gray-700">Filtro</label>
            <select id="filter" class="border rounded px-4 py-2">
              <option>Nombre</option>
            </select>
            <input type="text" class="border rounded px-4 py-2" placeholder="Buscar..." />
          </div>
        </div>
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Nombre</th>
              <th class="py-2 px-4 border-b">RFC</th>
              <th class="py-2 px-4 border-b">Creacion</th>
              <th class="py-2 px-4 border-b">Actualizacion</th>
              <th class="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <!-- <td class="py-2 px-4 border-b">{{ reporte.folio }}</td>
              <td class="py-2 px-4 border-b">
                <div class="flex items-center">
                  <img class="h-10 w-10 rounded-full mr-4" [src]="reporte.usuarioImagen" alt="{{ reporte.usuario }}">
                  <div>{{ reporte.usuario }}</div>
                </div>
              </td>
              <td class="py-2 px-4 border-b">{{ reporte.cajero }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaPago }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaCreacion }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaAutorizacion }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.monto | currency }}</td>
              <td class="py-2 px-4 border-b">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                  {{ reporte.estado }}
                </span>
              </td>
              <td class="py-2 px-4 border-b">
                <button class="bg-blue-500 text-white px-4 py-2 rounded">Ver Detalles</button>
              </td> -->
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border rounded px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para envíos -->
<div *ngIf="showEnviosModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold text-dagblue">Detalles del Envío</h2>
        <button (click)="closeEnviosModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input type="text" placeholder="Filtrar por nombre o pro ..." 
               class="flex-grow border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue mb-2 md:mb-0">
        <div class="flex space-x-2 items-center">
          <div class="flex space-x-2">
            <input type="date" placeholder="Desde" 
                   class="border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue">
            <input type="date" placeholder="Hasta" 
                   class="border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue">
            <button class="bg-white border-2 border-gray-400 rounded-full px-2 py-2">
              <svg class="h-6 w-6 text-green-500" fill="currentColor" viewBox="0 0 24 24">
                <path d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"/>
                <path d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"/>
              </svg>
            </button>
          </div>
          <button class="bg-white border-2 border-gray-400 rounded-full px-2 py-2">
            <svg class="h-6 w-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
              <path d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="min-w-full bg-[#F0F0F0]">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th class="py-2 px-4 border-b">ID del Envío</th>
              <th class="py-2 px-4 border-b">Nombre</th>
              <th class="py-2 px-4 border-b">ID del Usuario</th>
              <th class="py-2 px-4 border-b">Tipo</th>
              <th class="py-2 px-4 border-b">Estado</th>
              <th class="py-2 px-4 border-b">Fecha</th>
            </tr>
          </thead>
          <tbody class="text-gray-500">
            <tr *ngFor="let shipment of shipments" class="hover:bg-gray-200">
              <td class="py-2 px-4 border-b">{{ shipment._id }}</td>
              <td class="py-2 px-4 border-b">{{ shipment.from.name }}</td>
              <td class="py-2 px-4 border-b">{{ shipment.user_id }}</td>
              <td class="py-2 px-4 border-b">{{ shipment.shipment_type }}</td>
              <td class="py-2 px-4 border-b">
                <span [ngClass]="{
                  'text-green-600 ': shipment.payment.status === 'Pagado',
                  'text-red-600 ': shipment.payment.status === 'Pendiente'
                }"  class="font-bold text-lg">
                  {{ shipment.payment.status }}
                </span>
              </td>
              <td class="py-2 px-4 border-b">{{ shipment.createdAt | date:'dd/MM/yyyy HH:mm' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border-2 border-gray-400 rounded-full px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1" 
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages" 
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para empaques-->
<div *ngIf="showEmpaques" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-2xl font-bold text-gray-900">Empaques</h3>
        <button (click)="closeEmpaquesModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="overflow-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <input type="text" class="border-2 border-gray-400 rounded-full px-4 py-2" placeholder="Buscar..." />
          </div>
        </div>
        <table class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th scope="col" class="py-2 px-4 border-b">Propietario</th>
              <th scope="col" class="py-2 px-4 border-b">Usuario Asignado</th>
              <th scope="col" class="py-2 px-4 border-b">Fecha Creación</th>
              <th scope="col" class="py-2 px-4 border-b">Tipo de Empaque</th>
              <th scope="col" class="py-2 px-4 border-b">Precio</th>
              <th scope="col" class="py-2 px-4 border-b">Costo</th>
            </tr>
          </thead>
          <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
            <tr *ngFor="let empaque of empaques" class="hover:bg-gray-200">
              <td class="px-6 py-4 whitespace-nowrap">{{ empaque.from.name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ empaque.to.name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ empaque.createdAt | date: 'dd/MM/yyyy HH:mm' }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ empaque.packing.packing_type }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ empaque.price?.$numberDecimal || '0.00' }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ empaque.cost?.$numberDecimal || '0.00' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border-2 border-gray-400 rounded-full px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1"
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages"
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para pago servicios S-->
<div *ngIf="showPagoS" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Registro de pagos</h2>
        <button (click)="closePagosSModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input type="text" placeholder="Filtrar por nombre o pro ..." class="border rounded px-4 py-2 mb-2 md:mb-0">
        <div class="flex space-x-2 items-center">
          <div class="flex space-x-2">
            <input type="date" placeholder="Desde" class="border rounded px-4 py-2">
            <input type="date" placeholder="Hasta" class="border rounded px-4 py-2">
            <button class="bg-white border rounded px-2 py-2">
              <svg class="h-6 w-6 text-green-500" fill="currentColor" viewBox="0 0 24 24">
                <path d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"/>
                <path d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"/>
              </svg>
            </button>
          </div>
          <button class="bg-white border rounded px-2 py-2">
            <svg class="h-6 w-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
              <path d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"/>
            </svg>
          </button>
        </div>
      </div>
      
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Usuario</th>
              <th class="py-2 px-4 border-b">Servicio</th>
              <th class="py-2 px-4 border-b">Fecha Pago</th>
              <th class="py-2 px-4 border-b">Num.Referencia</th>
              <th class="py-2 px-4 border-b">Num.Autorizacion</th>
              <th class="py-2 px-4 border-b">Monto</th>
              <th class="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr>
              <td class="py-2 px-4 border-b">Ejemplo Nombre</td>
              <td class="py-2 px-4 border-b">ABC123456</td>
              <td class="py-2 px-4 border-b">01/01/2024</td>
              <td class="py-2 px-4 border-b">15/01/2024</td>
              <td class="py-2 px-4 border-b">Activo</td>
            </tr> -->
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border rounded px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para pago servicios R-->
<div *ngIf="showPagoR" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-2xl font-bold text-gray-900">Registro de Pagos</h3>
        <button (click)="closePagoRModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="overflow-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <input type="text" class="border-2 border-gray-400 rounded-full px-4 py-2" placeholder="Buscar..." />
          </div>
        </div>
        <table class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th scope="col" class="py-2 px-4 border-b">ID</th>
              <th scope="col" class="py-2 px-4 border-b">Servicio</th>
              <th scope="col" class="py-2 px-4 border-b">Fecha Pago</th>
              <th scope="col" class="py-2 px-4 border-b">Num. Referencia</th>
              <th scope="col" class="py-2 px-4 border-b">Método de Pago</th>
              <th scope="col" class="py-2 px-4 border-b">Monto</th>
              <th scope="col" class="py-2 px-4 border-b">Estado</th>
            </tr>
          </thead>
          <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
            <tr *ngFor="let transaction of transactions; let i = index" class="hover:bg-gray-200">
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction._id }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction.details }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction.transaction_date | date:'dd/MM/yyyy HH:mm' }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction.transaction_number }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction.payment_method }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction.amount?.$numberDecimal || '0.00' }}</td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span [ngClass]="{
                  'text-green-600': transaction.status === 'Pagado',
                  'text-yellow-500': transaction.status === 'Pendiente',
                  'text-red-500': transaction.status === 'Cancelado'
                }" class="font-bold text-lg">
                  {{ transaction.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border-2 border-gray-400 rounded-full px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1"
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages"
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para reparto-->
<div *ngIf="showRepartoM" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Reparto Multimarca</h2>
        <button (click)="closeRepartoMModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input type="text" placeholder="Filtrar por nombre o pro ..." class="border rounded px-4 py-2 mb-2 md:mb-0">
        <div class="flex space-x-2 items-center">
          <div class="flex space-x-2">
            <input type="date" placeholder="Desde" class="border rounded px-4 py-2">
            <input type="date" placeholder="Hasta" class="border rounded px-4 py-2">
            <button class="bg-white border rounded px-2 py-2">
              <svg class="h-6 w-6 text-green-500" fill="currentColor" viewBox="0 0 24 24">
                <path d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"/>
                <path d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"/>
              </svg>
            </button>
          </div>
          <button class="bg-white border rounded px-2 py-2">
            <svg class="h-6 w-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
              <path d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"/>
            </svg>
          </button>
        </div>
      </div>
      
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">#</th>
              <th class="py-2 px-4 border-b">Fecha</th>
              <th class="py-2 px-4 border-b">Licenciatario</th>
              <th class="py-2 px-4 border-b">Usuario</th>
              <th class="py-2 px-4 border-b">Nombre Remitente</th>
              <th class="py-2 px-4 border-b">Telefono</th>
              <th class="py-2 px-4 border-b">Status</th>
              <th class="py-2 px-4 border-b">Recibo Pdf</th>
              <th class="py-2 px-4 border-b">Paqueteria</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr>
              <td class="py-2 px-4 border-b">Ejemplo Nombre</td>
              <td class="py-2 px-4 border-b">ABC123456</td>
              <td class="py-2 px-4 border-b">01/01/2024</td>
              <td class="py-2 px-4 border-b">15/01/2024</td>
              <td class="py-2 px-4 border-b">Activo</td>
            </tr> -->
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border rounded px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para cupones-->
<div *ngIf="showCupones" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Registro de cupones</h2>
        <button (click)="closeCuponesModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input type="text" placeholder="Buscar Pago ..." class="border rounded px-4 py-2 mb-2 md:mb-0">
        <div class="flex space-x-2 items-center">
          <button class="bg-white border rounded px-2 py-2">
            <svg class="h-6 w-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
              <path d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"/>
            </svg>
          </button>
        </div>
      </div>
      
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Cupon</th>
              <th class="py-2 px-4 border-b">Licenciatario</th>
              <th class="py-2 px-4 border-b">Fecha</th>
              <th class="py-2 px-4 border-b">Tipo</th>
              <th class="py-2 px-4 border-b">Valor</th>
              <th class="py-2 px-4 border-b">Disponibiliad</th>
              <th class="py-2 px-4 border-b">Usos</th>
              <th class="py-2 px-4 border-b">Limite de usos</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr>
              <td class="py-2 px-4 border-b">Ejemplo Nombre</td>
              <td class="py-2 px-4 border-b">ABC123456</td>
              <td class="py-2 px-4 border-b">01/01/2024</td>
              <td class="py-2 px-4 border-b">15/01/2024</td>
              <td class="py-2 px-4 border-b">Activo</td>
            </tr> -->
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border rounded px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para Direcciones-->
<div *ngIf="showDirecciones" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold text-dagblue">Lista de Direcciones</h2>
        <button (click)="closeDireccionesModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input type="text" placeholder="Filtrar por nombre o pro ..." class="flex-grow border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue mb-2 md:mb-0" />
        <div class="flex space-x-2 items-center">
          <button class="bg-white border-2 border-gray-400 rounded-full px-2 py-2">
            <svg class="h-6 w-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
              <path d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"/>
            </svg>
          </button>
        </div>
      </div>
      
      <div class="overflow-x-auto">
        <table class="min-w-full bg-[#F0F0F0]">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th class="py-2 px-4 border-b">C.P</th>
              <th class="py-2 px-4 border-b">Nombre</th>
              <th class="py-2 px-4 border-b">Nacional</th>
              <th class="py-2 px-4 border-b">Pais</th>
              <th class="py-2 px-4 border-b">Dirección</th>
              <th class="py-2 px-4 border-b">Usuario</th>
            </tr>
          </thead>
          <tbody class="text-gray-500">
            <tr *ngFor="let address of addresses" class="hover:bg-gray-200">
              <td class="py-2 px-4 border-b">{{ address.zip_code }}</td>
              <td class="py-2 px-4 border-b">{{ address.name }}</td>
              <td class="py-2 px-4 border-b">{{ address.settlement }}</td>
              <td class="py-2 px-4 border-b">{{ address.country }}</td>
              <td class="py-2 px-4 border-b">{{ address.street }}, {{ address.city }}, {{ address.state }}</td>
              <td class="py-2 px-4 border-b">{{ address.user }}</td>
            </tr>
          </tbody>
        </table>

        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border-2 border-gray-400 rounded-full px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1" 
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages" 
                  class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para Recotizciones-->
<div *ngIf="showRecotizciones" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Recotizaciones</h2>
        <button (click)="closeRecotizcionesModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input type="text" placeholder="Buscar Usuario..." class="border rounded px-4 py-2 mb-2 md:mb-0">
        <div class="flex space-x-2 items-center">
          <div class="flex space-x-2">
            <button class="bg-white border rounded px-2 py-2">
              <svg class="h-6 w-6 text-green-500" fill="currentColor" viewBox="0 0 24 24">
                <path d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"/>
                <path d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"/>
              </svg>
            </button>
          </div>
          <button class="bg-white border rounded px-2 py-2">
            <svg class="h-6 w-6 text-gray-500" fill="currentColor" viewBox="0 0 24 24">
              <path d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"/>
            </svg>
          </button>
        </div>
      </div>
      
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Fecha Creacion</th>
              <th class="py-2 px-4 border-b">Envio</th>
              <th class="py-2 px-4 border-b">Precio a Completar</th>
              <th class="py-2 px-4 border-b">Estado</th>>
            </tr>
          </thead>
          <tbody>
            <!-- <tr>
              <td class="py-2 px-4 border-b">Ejemplo Nombre</td>
              <td class="py-2 px-4 border-b">ABC123456</td>
              <td class="py-2 px-4 border-b">01/01/2024</td>
              <td class="py-2 px-4 border-b">15/01/2024</td>
              <td class="py-2 px-4 border-b">Activo</td>
            </tr> -->
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <span class="text-gray-500">Rows per page:</span>
          <select class="border rounded px-2 py-1" (change)="onItemsPerPageChange($event)">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="25">25</option>
          </select>
          <span class="text-gray-500">{{ currentPage }}–{{ totalPages }} of {{ totalPages }}</span>
          <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
          <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>

