import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService } from 'src/app/_services/users/user-service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  token: string = '';
  password: string = '';
  confirmPassword: string = '';
  message: string = '';
  error: boolean = false;
  isLoading: boolean = false;
  isSuccess: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  passwordMismatch: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: UserServiceService
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token') || '';
  }

  togglePasswordVisibility(field: 'password' | 'confirmPassword') {
    if (field === 'password') {
      this.showPassword = !this.showPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  checkPasswordsMatch() {
    this.passwordMismatch = this.password !== this.confirmPassword;
  }

  onSubmit() {
    if (this.passwordMismatch) {
      this.showAlert('Las contraseñas no coinciden', true);
      return;
    }

    this.isLoading = true;
    this.authService.resetPassword(this.token, this.password).subscribe(
      response => {
        this.isLoading = false;
        this.isSuccess = true;
        setTimeout(() => this.router.navigate(['/login']), 3000);
      },
      error => {
        this.isLoading = false;
        this.showAlert('Ha ocurrido un error al restablecer tu contraseña', true);
      }
    );
  }

  private showAlert(message: string, isError: boolean) {
    this.message = message;
    this.error = isError;
    setTimeout(() => {
      this.message = '';
    }, 5000);
  }
}