<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
    <div class="p-6">        
         <span class="font-bold text-dagblue uppercase text-2xl"> Historial de Cotizaciones </span>        
         <!-- Paquetes en recoleccion -->
         <div class="flex justify-end items-center mb-4">
              
              <!-- Filtro por estatus -->
              <div class="flex items-center mr-4">
                 <label for="status-filter" class="mr-2">Estatus:</label>
                 <select id="status-filter" class="border rounded p-1">
                    <option value="">Todos</option>
                    <option value="en-reparto">En reparto</option>
                    <option value="en-recoleccion">En Recolección</option>
                    <option value="problema">Problema</option>
                    <option value="entregado">Entregado</option>
                 </select>
              </div>
              
              <!-- Botón para aplicar filtros -->
              <button class="bg-dagblue text-white px-4 py-2 rounded">
                 Aplicar Filtro
              </button>
            </div>

         <div class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4">            
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-dagblue">
                         <tr class="text-sm text-white">
                              <th scope="col" class="px-6 py-3">Paquetería</th>
                              <th scope="col" class="px-6 py-3">Fecha</th>
                              <th scope="col" class="px-6 py-3">Costo</th>
                              <th scope="col" class="px-6 py-3">Utilidad</th>
                              <th scope="col" class="px-6 py-3">Precio</th>
                              <th scope="col" class="px-6 py-3">Tu utilidad</th>
                              <th scope="col" class="px-6 py-3">Origen</th>
                              <th scope="col" class="px-6 py-3">Destino</th>
                              <th scope="col" class="px-6 py-3">Estado de pago</th>
                              <th scope="col" class="px-6 py-3">Estado de envío</th>
                         </tr>
                    </thead>
                    <tbody class="text-gray-900 text-medium">
                         <tr class="bg-white border-b duration-200">
                              <td class="px-6 py-4 uppercase">DHL</td>
                              <td class="px-6 py-4">22/01/2024</td>
                              <td class="px-6 py-4">$100.00</td>
                              <td class="px-6 py-4">$20.00</td>
                              <td class="px-6 py-4">$120.00</td>
                              <td class="px-6 py-4">$15.00</td>
                              <td class="px-6 py-4">Ciudad de México</td>
                              <td class="px-6 py-4">Guadalajara</td>
                              <td class="px-6 py-4">
                                    <span class="bg-green-500 text-white px-2 font-bold rounded-lg">Pagado</span>
                              </td>
                              <td class="px-6 py-4">
                                    <span class="bg-gradient-to-r from-dagpk to-yellow-600 text-white px-2 font-bold rounded-lg">En reparto</span>
                              </td>
                              <td class="px-6 py-4 uppercase">
                                    <div class="flex gap-2">
                                         <div class="has-tooltip">
                                              <span class='tooltip rounded shadow-lg p-2 bg-gray-100 -mt-8'>Editar</span>
                                         </div>
                                    </div>
                              </td>
                         </tr>
                    </tbody>
              </table>
         </div>
    </div>
</div>

<div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
         <p class="text-sm text-gray-700">
            Mostrando
            <span class="font-medium">1</span>
            a
            <span class="font-medium">10</span>
            de
            <span class="font-medium">97</span>
            resultados
         </p>
      </div>
    </div>
</div>
