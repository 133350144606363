import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private apiKey = environment.googleApi;

  constructor(private http: HttpClient) {}

  
  getCountryCodeFromPostalCode(postalCode: string, country?: string): Observable<string> {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${this.apiKey}`;
    
    if (country) {
      url += `&components=country:${country}`;
    }

    return this.http.get(url).pipe(
      map((response: any) => {
        if (response.results && response.results.length > 0) {
          const addressComponents = response.results[0].address_components;
          const countryComponent = addressComponents.find((component: any) => 
            component.types.includes('country')
          );
          return countryComponent ? countryComponent.short_name : '';
        }
        return '';
      })
    );
  }

  getAddressDetails(placeId: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${this.apiKey}`;
    return this.http.get(url).pipe(
      map((response: any) => {
        if (response.status === 'OK') {
          return this.processAddressComponents(response.results[0]);
        }
        throw new Error('No se pudo obtener la información de la dirección');
      })
    );
  }

  private processAddressComponents(result: any): any {
    const addressData: any = {};
    
    for (const component of result.address_components) {
      const type = component.types[0];
      switch (type) {
        case 'locality':
          addressData.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          addressData.state = component.long_name;
          addressData.iso_estado = component.short_name;
          break;
        case 'country':
          addressData.country = component.long_name;
          addressData.iso_pais = component.short_name;
          break;
        case 'postal_code':
          addressData.zip_code = component.long_name;
          break;
        // Añade más casos según sea necesario
      }
    }

    // Aquí podrías hacer llamadas adicionales a otros servicios si necesitas más información
    // Por ejemplo, para obtener clave_colonia, clave_localidad, clave_municipio, etc.

    return addressData;
  }
}