<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
  <div class="p-6">
    <span class="font-bold text-dagblue uppercase text-2xl">Estatus de los paquetes</span>
    <p>Detalles de los envíos</p>
    
    <div class="flex justify-end items-center mb-4 space-x-2">
      <button class="bg-gray-300 text-gray-700 px-4 py-2 rounded-full flex items-center" (click)="exportToCSV()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#217346" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6 mr-2">
          <path d="M4.5 3.75A1.5 1.5 0 0 1 6 2.25h12a1.5 1.5 0 0 1 1.5 1.5v16.5a1.5 1.5 0 0 1-1.5 1.5H6a1.5 1.5 0 0 1-1.5-1.5V3.75z" fill="#217346"/>
          <path fill="#fff" d="M8.5 8.25h2.75L12.75 12l-1.5 3.75H8.5L10 12 8.5 8.25zm7 0h1.5v7.5H15.5V8.25z"/>
        </svg>
        Exportar a CSV
      </button>
      <div class="flex items-center">
        <label for="start-date" class="mr-2 font-semibold">Desde:</label>
        <input type="date" id="start-date" class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold" [(ngModel)]="startDate">
      </div>
      <div class="flex items-center">
        <label for="end-date" class="mr-2 font-semibold">Hasta:</label>
        <input type="date" id="end-date" class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold" [(ngModel)]="endDate">
      </div>
      <select id="status-filter" class="border-2 border-gray-400 rounded-full p-2 text-gray-700 font-semibold" [(ngModel)]="statusFilter">
        <option value="">Todos</option>
        <option value="Pendiente">Pendiente</option>
        <option value="Pagado">Pagado</option>
      </select>
      <button class="bg-[#D6542B] text-white px-4 py-2 rounded-full font-bold" (click)="applyFilters()">
        FILTRAR
      </button>
    </div>
    

    <div class="overflow-x-auto shadow-md sm:rounded-lg w-full my-4 bg-[#F0F0F0]">            
      <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-lg uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th scope="col" class="px-6 py-3">Paquetería</th>
            <th scope="col" class="px-6 py-3">Fecha</th>
            <th scope="col" class="px-6 py-3">Costo</th>
            <th scope="col" class="px-6 py-3">Utilidad Global</th>
            <th scope="col" class="px-6 py-3">Precio</th>
            <th scope="col" class="px-6 py-3">Tu utilidad</th>
            <th scope="col" class="px-6 py-3">U. Dagpacket</th>
            <th scope="col" class="px-6 py-3">Origen</th>
            <th scope="col" class="px-6 py-3">Destino</th>
            <th scope="col" class="px-6 py-3 text-center">Estado de pago</th>
            <th scope="col" class="px-6 py-3 text-center">Estado de envío</th>
            <th scope="col" class="px-6 py-3">Acciones</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr *ngFor="let envio of filteredPackages" class="hover:bg-gray-50">
            <td class="px-6 py-4 uppercase">{{ envio.provider }}</td>
            <td class="px-6 py-4">{{ envio.distribution_at | date:'dd/MM/yy' }}</td>
            <td class="px-6 py-4">${{ envio.cost.$numberDecimal }}</td>
            <td class="px-6 py-4">${{ envio.dagpacket_profit.$numberDecimal | number:'1.2-2' }}</td>
            <td class="px-6 py-4">${{ envio.price.$numberDecimal | number:'1.2-2' }}</td>
            <td class="px-6 py-4">${{ envio.utilitie_lic.$numberDecimal | number:'1.2-2' }}</td>
            <td class="px-6 py-4">${{ envio.utilitie_dag.$numberDecimal | number:'1.2-2' }}</td>
            <td class="px-6 py-4">{{ envio.from.city }}</td>
            <td class="px-6 py-4">{{ envio.to.city }}</td>
            <td class="px-6 py-4 text-center">
              <span class="font-bold text-lg"
                [ngClass]="{
                  'text-green-600': envio.payment.status.toLowerCase() === 'pagado',
                  'text-yellow-500': envio.payment.status.toLowerCase() === 'pendiente'
                }">
                {{ envio.payment.status }}
              </span>
            </td>
            <td class="px-6 py-4 text-center font-bold divide-gray-200">
              {{ envio.status }}
            </td>
            <td class="px-6 py-4">
              <div class="flex justify-center space-x-2">
                <button (click)="viewDetails(envio._id)" class="hover:text-dagblue">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5s8.268 2.943 9.542 7c-1.274 4.057-5.065 7-9.542 7S3.732 16.057 2.458 12z" />
                  </svg>
                </button>
              </div>
            </td>                      
          </tr>
          
        </tbody>
      </table>
    </div>

    <div class="flex justify-center items-center space-x-4 py-4">
      <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1"
              class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </button>
      <span class="text-xl font-bold">{{ currentPage }}</span>
      <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages"
              class="flex items-center justify-center w-10 h-10 rounded-full border-2 border-dagblue text-dagblue hover:bg-dagblue hover:text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>
    
  </div>
</div>




