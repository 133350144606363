import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';
import { Transaction } from 'src/app/_models/history_refills';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-history-refills',
  templateUrl: './history-refills.component.html',
  styleUrls: ['./history-refills.component.scss']
})
export class HistoryRefillsComponent implements OnInit {

  transactions: any[] = [];
  paginatedTransactions: any[] = [];
  currentPage: number = 1;
  pageSize: number = 10; // Tamaño de página
  totalPages: number = 1;

  constructor(private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.transactionService.getTransactions().subscribe(
      (data: any[]) => {
        this.transactions = data.map(transaction => ({
          ...transaction,
          Response_Transaction: this.cleanTransactionResponse(transaction.Response_Transaction)
        }));
        this.totalPages = Math.ceil(this.transactions.length / this.pageSize);
        this.updatePaginatedTransactions();
      },
      (error) => {
        console.error('Error al obtener las transacciones', error);
      }
    );
  }

  cleanTransactionResponse(response: string): string {
    if (response && response.includes('Invalid Amount')) {
      return 'Invalid Amount';
    }
    if (response && response.includes('SITE IS DISABLED')) {
      return 'SITE IS DISABLED';
    }
    return response;
  }

  updatePaginatedTransactions(): void {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedTransactions = this.transactions.slice(start, end);
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updatePaginatedTransactions();
    }
  }

  downloadCSV(): void {
    const csvData = this.convertToCSV(this.transactions);
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'historial_recargas.csv');
  }

  convertToCSV(transactions: any[]): string {
    const headers = ['Fecha', 'Terminal', 'Respuesta', 'Invoice', 'Producto', 'Cantidad', 'Cuenta', 'Response Code'];
    const csvRows = [headers.map(header => this.escapeCSV(header))];

    for (const transaction of transactions) {
      const date = new Date(transaction.Date_Time);
      const formattedDate = date.toLocaleString('en-GB', { 
        day: '2-digit', 
        month: '2-digit', 
        year: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit' 
      });
      const row = [
        this.escapeCSV(formattedDate),
        this.escapeCSV(transaction.Terminal_Id),
        this.escapeCSV(transaction.Response_Transaction),
        this.escapeCSV(transaction.Inovice_Id),
        this.escapeCSV(transaction.Product_Id),
        this.escapeCSV(transaction.Amount_Id),
        this.escapeCSV(transaction.Account_Id),
        this.escapeCSV(transaction.ResponseCode)
      ];
      csvRows.push(row);
    }

    return csvRows.map(row => row.join(';')).join('\r\n');
  }

  escapeCSV(value: any): string {
    if (value == null) {
      return '';
    }
    const stringValue = value.toString();
    if (stringValue.includes(';') || stringValue.includes('"') || stringValue.includes('\n')) {
      return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
  }
}
