import { Component, OnInit } from '@angular/core';
import { ChartData } from 'chart.js/auto';
import { UserServiceService } from 'src/app/_services/users/user-service.service';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { RefillRequestService } from 'src/app/_services/Refills/refill-request.service';
import { CancellationService } from 'src/app/_services/cancellations/cancellation.service';
import { AddressService } from 'src/app/_services/Address/address.service';
import { CustomerService } from 'src/app/_services/Customer/customer.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service'; 
import { TransactionService } from 'src/app/_services/Transaction/transaction.service';

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.scss']
})
export class AdministracionComponent implements OnInit {
  reportes = [
    {
      folio: '4026',
      usuario: 'gtoleon-001 Fabian Gonzalez Villanueva',
      usuarioImagen: 'ruta_a_la_imagen_del_usuario',
      cajero: 'gtoleon-001 Fabian Gonzalez Villanueva',
      fechaPago: '23 July 2024',
      fechaCreacion: '23 Jul 2024 09:21',
      fechaAutorizacion: '-',
      monto: 410,
      estado: 'Pendiente'
    },
  ];

  showTransactionsModal = false;
  selectedUser: any = null;
  transactions: any[] = [];
  showDownloadForm = false;
  showUserModal = false;
  showCancellationModal = false;
  showReporteModal = false;
  showPedidoModal = false;
  showEmpaques = false;
  showPagoS = false;
  showPagoR = false;
  showRepartoM = false;
  showCupones = false;
  showDirecciones = false;
  showPMercado = false;
  showRecotizciones = false;
  selectedDate: string = '';
  users: any[] = [];
  shipments: any[] = [];
  cancellations: any[] = []; 
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 10;
  totalCancellations: number = 0;
  showEnviosModal: boolean = false;
  totalShipments: number = 0;
  quincenalProfit: number = 0; 

  profitData: any;
  errorMessage: string | null = null;




  chartData: ChartData<'bar'> = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    datasets: [
      {
        label: 'Series A',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      },
      {
        label: 'Series B',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1
      }
    ]
  };

  showDetallesModal: boolean = false;
  reporteSeleccionado: any = null;

  globalProfit: any;
  shipmentDetails: any; 

  addresses: any[] = [];
  totalDirecciones: number = 0;
  totalUsers: number = 0;

  searchTerm: string = ''; 
  filteredUsers: any[] = []; 

  empaques: any[] = [];
  

  constructor(
    private authService: AuthLoginService, 
    private userService: UserServiceService,
     private shipmentsService: ShipmentsService, 
     private cancellationService: CancellationService, 
     private addressService: AddressService,
     private refillRequestService: RefillRequestService,
     private customerService: CustomerService,
     private transactionService: TransactionService
    ) {}
 //carga al iniciar
  ngOnInit(): void {
    this.loadGlobalProfit();
    this.loadShipments(1, 10, 'date', 'asc');
    this.loadCancellations(this.currentPage, this.itemsPerPage, 'requested_at', 'desc', '');
    this.updateChartData(); 
    this.loadAddresses();
    this.loadTotalDirecciones();
    this.loadAllUsers();
    this.loadTotalUsers();
    this.fetchQuincenalProfit();
    this.fetchTransactionsByType();
    this.fetchTransactionsByTypeAndUser();
    const userId = this.authService.getId(); // Obtén el ID del usuario desde el almacenamiento local
    if (userId) { // Verifica si userId no es null
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const quincena = 1;
  
      this.loadQuincenalProfit(userId, year, month, quincena); // Llama a la función 
    } else {
      console.error('No se encontró el ID de usuario en localStorage');
    }
  }

  loadGlobalProfit(): void {
    this.shipmentsService.getGlobalProfit().subscribe(
      (data) => {
        this.globalProfit = data.monthlyProfit; 
      },
      (error) => {
        console.error('Error al cargar los datos de ganancias:', error);
      }
    );
  }

  loadShipments(page: number, limit: number, sortBy: string, sortOrder: 'asc' | 'desc', searchBy: string = 'name'): void {
    this.shipmentsService.getPaidShipments(page, limit, sortBy, sortOrder, searchBy).subscribe(
      data => {
        this.shipments = data.data.shipments; // Asigna los envíos pagados
        this.totalShipments = data.data.totalShipments;
        this.totalPages = data.data.totalPages;
      },
      error => {
        console.error('Error al cargar los envíos pagados:', error);
      }
    );
  }
  

  
  //direcciones
  loadAddresses(): void {
    this.addressService.getAddresses().subscribe(
      (response: any) => {
        console.log('Respuesta de getAddresses:', response); // Verifica la estructura de la respuesta
        this.addresses = response.data || response; // Ajusta aquí según corresponda
      },
      (error: any) => {
        console.error('Error al cargar las direcciones:', error);
      }
    );
  }

  loadTotalDirecciones(): void {
    this.addressService.getAddresses(10000).subscribe(
      (response: any) => {
        this.totalDirecciones = response.data.length; // Usa la longitud de data
        console.log('Total de direcciones:', this.totalDirecciones);
      },
      (error: any) => {
        console.error('Error al obtener el total de direcciones:', error);
      }
    );
  }
  
  openTransactionsModal(user: any): void {
    this.selectedUser = user;
    this.showTransactionsModal = true;
    this.loadUserTransactions(user.id);
  }

  loadUserTransactions(userId: number): void {
    this.transactions = [
      { date: '2023-01-01', amount: 100, description: 'Transaction 1' },
      { date: '2023-02-01', amount: 200, description: 'Transaction 2' }
    ];
  }
  
  closeTransactionsModal(): void {
    this.showTransactionsModal = false;
    this.selectedUser = null;
    this.transactions = [];
  }
   
  onApply() {
    if (this.selectedDate) {
      this.updateChartData();
    }
  }

  onDateChange(event: any) {
    this.selectedDate = event.target.value;
  }

  toggleDownloadForm() {
    this.showDownloadForm = !this.showDownloadForm;
  }

  updateChartData() {
    this.chartData = {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
      datasets: [
        {
          label: 'Series A',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
        {
          label: 'Series B',
          data: [28, 48, 40, 19, 86, 27, 90],
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1
        }
      ]
    };
  }

  //usuarios

openUserModal() {
  this.showUserModal = true;
  this.loadAllUsers(); // Cargar usuarios al abrir el modal
}

closeUserModal() {
    this.showUserModal = false;
}

// Cargar todos los usuarios y mostrarlos al abrir el modal
loadAllUsers(): void {
    this.userService.listUsers(1, 10000).subscribe(
      (response: any) => {
        this.users = response.data.users;
        this.filteredUsers = [...this.users]; // Mostrar todos los usuarios inicialmente
        console.log('Usuarios obtenidos:', this.users);
      },
      (error: any) => {
        console.error('Error al obtener usuarios:', error);
      }
    );
}

// Método para buscar en la lista de usuarios
onSearch(): void {
    const term = this.searchTerm.toLowerCase();
    if (!term) {
      // Si no hay término de búsqueda, mostrar todos los usuarios
      this.filteredUsers = [...this.users];
    } else {
      // Filtrar la lista de usuarios según el término de búsqueda
      this.filteredUsers = this.users.filter(user => 
        user.name.toLowerCase().includes(term) ||
        user.email.toLowerCase().includes(term) ||
        user.role.toLowerCase().includes(term)
      );
    }
}


  loadTotalUsers(): void {
    this.userService.listUsers(1, 10000).subscribe(
      (response: any) => {
        console.log('Usuarios obtenidos:', response);
        if (response && response.data && response.data.users && Array.isArray(response.data.users)) {
          this.totalUsers = response.data.users.length;
        } else {
          this.totalUsers = 0;
          console.warn('No se encontró la lista de usuarios o la estructura es incorrecta.');
        }
        console.log('Total de usuarios:', this.totalUsers);
      },
      (error: any) => {
        console.error('Error al obtener el total de usuarios:', error);
      }
    );
  }

  onPageChange(page: number): void {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadAllUsers(); 
      this.loadShipments(this.currentPage, this.itemsPerPage, 'status', 'asc');
    }
  }
  

  openReporteModal() {
    this.showReporteModal = true;
  }

  openPedidoModal() {
    this.showPedidoModal = true;
  }
 
  closeEnviosModal(): void {
    this.showEnviosModal = false;
  }

  openEnviosModal(): void {
    this.showEnviosModal = true;
  }

  loadShipmentDetails(shipmentId: string): void {
    this.shipmentsService.getShipmentDetails(shipmentId).subscribe(
      (data) => {
        console.log('Datos de cancelaciones:', data); 
        this.shipmentDetails = data; 
      },
      (error) => {
        console.error('Error al cargar los detalles del envío:', error);
      }
    );
  }
  
  loadQuincenalProfit(userId: string, year: number, month: number, quincena: 1 | 2): void {
    this.shipmentsService.getQuincenalProfit(userId, year, month, quincena).subscribe(
      response => {
        if (response && response.data && response.data.shipmentProfit) {
          const shipmentProfit = response.data.shipmentProfit as any;
          this.quincenalProfit = parseFloat(shipmentProfit.$numberDecimal);
        } else {
          console.warn('No se recibió shipmentProfit en el formato esperado');
        }
      },
      error => {
        console.error('Error al obtener la utilidad quincenal:', error);
      }
    );
  }
  
  onItemsPerPageChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.itemsPerPage = Number(target.value);
    this.currentPage = 1; 
    this.loadShipments(this.currentPage, this.itemsPerPage, 'status', 'asc');
  }


  openCancellationModal(): void {
    this.showCancellationModal = true;
    this.loadCancellations(this.currentPage, this.itemsPerPage, 'requested_at', 'desc', '');
  }
  
  openEmpaquesModal() {
    this.showEmpaques = true;
  }

  openPagoSModal() {
    this.showPagoS = true;
  }

  openPagoRModal() {
    this.showPagoR = true;
  }

  openRepartoMModal() {
    this.showRepartoM = true;
  }

  openCuponesModal() {
    this.showCupones = true;
  }

  openDireccionesModal() {
    this.showDirecciones = true;
  }

  openRecotizcionesModal() {
    this.showRecotizciones = true;
  }

  closeCancellationModal() {
    this.showCancellationModal = false;
  }

  closeReporteModal() {
    this.showReporteModal = false;
  }

  closePedidoModal() {
    this.showPedidoModal = false;
  }

  closeEmpaquesModal() {
    this.showEmpaques = false;
  }

  closePagosSModal() {
    this.showPagoS = false;
  }

  closePagoRModal() {
    this.showPagoR = false;
  }

  closeRepartoMModal() {
    this.showRepartoM = false;
  }

  closeCuponesModal() {
    this.showCupones = false;
  }

  closeDireccionesModal() {
    this.showDirecciones = false;
  }

  closeRecotizcionesModal() {
    this.showRecotizciones = false;
  }

  verDetalles(solicitud: any): void {
    this.showDetallesModal = true;
  }

  aceptarSolicitud(solicitud: any): void {
    console.log('Aceptar solicitud:', solicitud);
  }

  rechazarSolicitud(solicitud: any): void {
    const motivoRechazo = prompt('Ingrese el motivo de rechazo:');
    if (motivoRechazo) {
      console.log('Rechazar solicitud:', solicitud, 'Motivo:', motivoRechazo);
    }
  }

  closeDetallesModal(): void {
    this.showDetallesModal = false;
  }

  aplicarReporte(reporte: any): void {
    console.log('Aplicar reporte:', reporte);
  }

  rechazarReporte(reporte: any): void {
    const motivoRechazo = prompt('Ingrese el motivo de rechazo del reporte:');
    if (motivoRechazo) {
      console.log('Rechazar reporte:', reporte, 'Motivo:', motivoRechazo);
    }
  }

  loadCancellations(page: number = 1, limit: number = 10, sortBy: string = 'requested_at', sortOrder: 'desc', search: string = ''): void {
    this.cancellationService.getAllCancellations(page, limit, sortBy, sortOrder, search).subscribe(
      (data) => {
        this.cancellations = data.data.cancellations; 
        this.totalCancellations = data.data.totalCancellations; 
        this.totalPages = data.totalPages;
      },
      (error) => {
        console.error('Error al cargar los envíos:', error);
      }
    );
  }

  fetchQuincenalProfit(): void {
    const userId = this.authService.getId(); 
    if (!userId) {
      console.error('No se encontró el ID del usuario.');
      return; 
    }
  
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1; 
    const quincena = 1; 
  
    this.transactionService.getQuincenalProfit(userId, year, month, quincena).subscribe({
      next: (data) => {
        console.log('Utilidad quincenal:', data); 
        if (data?.message?.length > 0) {
          const firstMessage = data.message[0]; 
          this.profitData = parseFloat(firstMessage.total.$numberDecimal); 
        } else {
          console.warn('No se encontró información de utilidad quincenal.');
          this.profitData = 0; 
        }
      },
      error: (error) => {
        console.error('Error al obtener la utilidad quincenal:', error);
        this.profitData = 0; 
      }
    });
  }
  

  fetchTransactionsByType(): void {
    this.transactionService.getTransactionsByType('envio').subscribe({
      next: (data) => {
        console.log('Datos de transacciones por tipo:', data);
        this.transactions = data.message || []; // Asigna el array de transacciones
      },
      error: (error) => {
        console.error('Error al obtener las transacciones:', error);
        this.transactions = [];
      }
    });
  }

  fetchTransactionsByTypeAndUser(): void {
    const userId = this.authService.getId(); // Obtiene el ID del usuario dinámicamente
    if (!userId) {
        console.error('No se encontró el ID del usuario.');
        return;
    }

    const type = 'empaque'; // Tipo de transacción

    this.transactionService.getTransactionsByTypeAndUser(type, userId).subscribe({
        next: (data) => {
            console.log('Transacciones obtenidas por tipo y usuario:', data.message);
            this.empaques = data.message; // Asigna los datos obtenidos
        },
        error: (error) => {
            console.error('Error al obtener transacciones por tipo y usuario:', error);
        }
    });
}


 
  
  
  
}
