import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  showLoader = false;
  showPassword: boolean = false;
  successMessage: string = '';
  errorMessage: string = '';

  constructor(
    private authService: AuthLoginService,
    private router: Router
  ){}

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  signUp(registerForm: NgForm) {
    // Validación de formulario
    if (registerForm.invalid) {
      this.showTemporaryMessage('Por favor, completa todos los campos correctamente.', 'error');
      return;
    }

    this.showLoader = true;
    this.authService.signUpUser(registerForm.value).subscribe(
      (res) => {
        if (res.success) {
          this.showTemporaryMessage('Registro exitoso. Redirigiendo...', 'success');
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 2000); // Redirige después de 2 segundos
        } else {
          this.showTemporaryMessage(res.message || 'Hubo un problema con el registro.', 'error');
        }
      },
      (error) => {
        console.log('Error en la petición:', error);
        this.showTemporaryMessage('Hubo un problema al registrar la cuenta. Por favor, intenta nuevamente.', 'error');
        this.showLoader = false;
      },
      () => {
        // Ocultar el loader después de que la petición se haya completado (tanto en caso de éxito como de error)
        this.showLoader = false;
      }
    );
  }

  private showTemporaryMessage(message: string, type: 'success' | 'error') {
    if (type === 'success') {
      this.successMessage = message;
    } else {
      this.errorMessage = message;
    }

    setTimeout(() => {
      this.successMessage = '';
      this.errorMessage = '';
    }, 2000); // El mensaje desaparecerá después de 2 segundos
  }
}
