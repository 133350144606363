import { Component, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';

@Component({
  selector: 'app-new-packing-form',
  templateUrl: './new-packing-form.component.html',
  styleUrls: ['./new-packing-form.component.scss']
})
export class NewPackingFormComponent {
  @Output() closeModal = new EventEmitter<{ success: boolean, message: string }>();

  imageFile: File | null = null;
  alertMessage: string = '';
  showAlert: boolean = false;

  data: any = {
    name: '',
    sellPrice: 0,
    costPrice: 0,
    type: '',
    weight: 0,
    height: 0,
    width: 0,
    length: 0,
    description: '',
  };  

  constructor(private packingService: ShipmentsService) {}

  onImageSelected(event: any) {
    this.imageFile = event.target.files[0];
  }

  submitPacking(form: NgForm) {
    if (form.valid) {
      const formData = new FormData();
      
      // Añadir todos los campos del formulario
      Object.keys(this.data).forEach(key => {
        formData.append(key, this.data[key]);
      });
  
      // Añadir el archivo de imagen si existe
      if (this.imageFile) {
        formData.append('image', this.imageFile, this.imageFile.name);
      }
  
      this.packingService.createPacking(formData).subscribe(
        response => {
          console.log('Empaque creado:', response);
          this.closeModal.emit({ 
            success: true, 
            message: 'Empaque creado exitosamente' 
          });
        },
        error => {
          console.error('Error al crear el empaque:', error);
          this.closeModal.emit({ 
            success: false, 
            message: 'Error al crear el empaque: ' + (error.error?.message || error.message || 'Ocurrió un error desconocido')
          });
        }
      );
    } else {
      this.closeModal.emit({ 
        success: false, 
        message: 'Por favor, complete todos los campos requeridos'
      });
    }
  }
}