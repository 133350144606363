<div class="ml-64 mt-14">
    <div class="p-2">
        <div class="flex flex-col justify-between items-start py-2 overflow-x-auto">
            <div class="flex py-4 gap-2 hover:cursor-pointer hover:bg-slate-300 rounded-lg p-2">
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Usuario</span>
                    <p class="text-sm">DAGPACKET Puebla-001</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Servicio</span>
                    <p class="text-sm">Recarga Nextel ATT 50</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">FECHA</span>
                    <span class="bg-gradient-to-tr from-dagblue to to-blue-500 text-sm text-white rounded-lg px-2 font-bold">21 Dec 2024 11:30</span>
                </div>
                <div class="min-w-[300px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">No. Referencia</span>
                    <p class="text-sm">015012307032122309100000003293</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">No. Autorizacion</span>
                    <p class="text-sm">312114537</p>
                </div>
                <div class="min-w-[150px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Monto</span>
                    <p class="text-sm">$23.22</p>
                </div>
                <div class="min-w-[150px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Estatus</span>
                    <span class="text-white bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 rounded-lg"> Pagado </span>
                </div>
            </div>
            <div class="flex py-4 gap-2 hover:cursor-pointer hover:bg-slate-300 rounded-lg p-2">
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Usuario</span>
                    <p class="text-sm">DAGPACKET Puebla-001</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Servicio</span>
                    <p class="text-sm">Recarga Nextel ATT 50</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">FECHA</span>
                    <span class="bg-gradient-to-tr from-dagblue to to-blue-500 text-sm text-white rounded-lg px-2 font-bold">21 Dec 2024 11:30</span>
                </div>
                <div class="min-w-[300px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">No. Referencia</span>
                    <p class="text-sm">015012307032122309100000003293</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">No. Autorizacion</span>
                    <p class="text-sm">312114537</p>
                </div>
                <div class="min-w-[150px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Monto</span>
                    <p class="text-sm">$23.22</p>
                </div>
                <div class="min-w-[150px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Estatus</span>
                    <span class="text-white bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 rounded-lg"> Pagado </span>
                </div>
            </div>
            <div class="flex py-4 gap-2 hover:cursor-pointer hover:bg-slate-300 rounded-lg p-2">
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Usuario</span>
                    <p class="text-sm">DAGPACKET Puebla-001</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Servicio</span>
                    <p class="text-sm">Recarga Nextel ATT 50</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">FECHA</span>
                    <span class="bg-gradient-to-tr from-dagblue to to-blue-500 text-sm text-white rounded-lg px-2 font-bold">21 Dec 2024 11:30</span>
                </div>
                <div class="min-w-[300px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">No. Referencia</span>
                    <p class="text-sm">015012307032122309100000003293</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">No. Autorizacion</span>
                    <p class="text-sm">312114537</p>
                </div>
                <div class="min-w-[150px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Monto</span>
                    <p class="text-sm">$23.22</p>
                </div>
                <div class="min-w-[150px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Estatus</span>
                    <span class="text-white bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 rounded-lg"> Pagado </span>
                </div>
            </div>
            <div class="flex py-4 gap-2 hover:cursor-pointer hover:bg-slate-300 rounded-lg p-2">
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Usuario</span>
                    <p class="text-sm">DAGPACKET Puebla-001</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Servicio</span>
                    <p class="text-sm">Recarga Nextel ATT 50</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">FECHA</span>
                    <span class="bg-gradient-to-tr from-dagblue to to-blue-500 text-sm text-white rounded-lg px-2 font-bold">21 Dec 2024 11:30</span>
                </div>
                <div class="min-w-[300px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">No. Referencia</span>
                    <p class="text-sm">015012307032122309100000003293</p>
                </div>
                <div class="min-w-[200px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">No. Autorizacion</span>
                    <p class="text-sm">312114537</p>
                </div>
                <div class="min-w-[150px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Monto</span>
                    <p class="text-sm">$23.22</p>
                </div>
                <div class="min-w-[150px] flex flex-col items-start">
                    <span class="font-bold text-dagblue uppercase">Estatus</span>
                    <span class="text-white bg-gradient-to-r from-emerald-500 to-emerald-700 px-2 rounded-lg"> Pagado </span>
                </div>
            </div>
        </div>
    </div>
 </div>