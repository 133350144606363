<!-- new-packing-form.component.html -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
    <div class="bg-white p-5 rounded-lg shadow-lg w-[600px]">
        <h2 class="text-xl font-bold mb-4">Crear Nuevo Empaque</h2>
        <form #packingForm="ngForm" (ngSubmit)="submitPacking(packingForm)">
            <div class="mb-4">
                <label for="image" class="block text-sm font-bold text-gray-700">Imagen</label>
                <input type="file" id="image" name="image" [(ngModel)]="data.image" (change)="onImageSelected($event)" accept="image/*" required
                    class="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100">
            </div>
            <div class="flex gap-2">
                <div class="mb-4 w-1/2">
                    <label for="name" class="block text-sm font-bold text-gray-700">Nombre</label>
                    <input type="text" id="name" [(ngModel)]="data.name" name="name" required
                        class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>
                <div class="mb-4 w-1/2">
                    <label for="sell_price" class="block text-sm font-bold text-gray-700">Precio de Venta</label>
                    <input type="number" id="sell_price" [(ngModel)]="data.sell_price" name="sellPrice" min="0" required
                        class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>
            </div>

            <div class="mb-4">
                <label for="cost_price" class="block text-sm font-bold text-gray-700">Costo del empaque</label>
                <input type="number" id="cost_price" [(ngModel)]="data.cost_price" name="costPrice" min="0" required
                    class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>

            <div class="mb-4">
                <label for="type" class="block text-sm font-bold text-gray-700">Tipo</label>
                <input type="text" id="type" [(ngModel)]="data.type" name="type" required
                    class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>

            <div class="grid grid-cols-2 gap-4">
                <div class="mb-4">
                    <label for="weigth" class="block text-sm font-bold text-gray-700">Peso</label>
                    <input type="number" id="weigth" [(ngModel)]="data.weight" name="weight"
                        class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>

                <div class="mb-4">
                    <label for="height" class="block text-sm font-bold text-gray-700">Altura</label>
                    <input type="number" id="height" [(ngModel)]="data.height" name="height"
                        class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>

                <div class="mb-4">
                    <label for="width" class="block text-sm font-bold text-gray-700">Ancho</label>
                    <input type="number" id="width" [(ngModel)]="data.width" name="width"
                        class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>

                <div class="mb-4">
                    <label for="length" class="block text-sm font-bold text-gray-700">Longitud</label>
                    <input type="number" id="length" [(ngModel)]="data.length" name="length"
                        class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>
            </div>

            <div class="mb-4">
                <label for="description" class="block text-sm font-bold text-gray-700">Descripción</label>
                <textarea id="description" [(ngModel)]="data.description" name="description" required
                    class="mt-1 block w-full p-2 rounded-md border-gray-300 border shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
            </div>

            <div class="flex justify-center mt-6 w-full">
                <button type="button" (click)="closeModal.emit()"
                    class="w-full mr-4 px-6 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-300">
                    Cancelar
                </button>
                <button type="submit"
                    class="w-full px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300">
                    Guardar
                </button>
            </div>
        </form>
    </div>
</div>

<div *ngIf="showAlert" [ngClass]="{'bg-green-100 border-green-400 text-green-700': alertMessage.includes('exitosamente'), 'bg-red-100 border-red-400 text-red-700': alertMessage.includes('Error')}" class="border-l-4 p-4 mb-4" role="alert">
    <p class="font-bold">{{alertMessage.includes('exitosamente') ? 'Éxito' : 'Error'}}</p>
    <p>{{alertMessage}}</p>
  </div>
